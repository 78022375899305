import api from '../configs/api';

function getListNotification(type) {
  return api.apiForm('GET', `/api/v1/mc-ai/notification?limit=1000`);
};

function postUpdateFCMToken(data) {
  return api.apiForm('POST', `/api/v1/mc-ai/notification/fcm-token`, data);
};

function postUpdateReadNotification(id) {
  return api.apiForm('GET', `/api/v1/mc-ai/notification/${id}/seen`);
};

export {
  getListNotification,
  postUpdateReadNotification,
  postUpdateFCMToken
};
