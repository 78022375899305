import { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import './account-manager.css'

const options = [
  { label: 'Tra cứu', value: 'search' },
];
const optionsI = [
  { label: 'Lấy link chụp ảnh', value: 'getLink' },
  { label: 'Xuất báo cáo', value: 'export' },
];

export const AccountGara = ({
  handleSelectRole = () => { },
  title = '',
  roleData = [],
  userId = null,
  roleEdit = false,
  permissionManage = null
}) => {
  const [checkedList, setCheckedList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedListI, setCheckedListI] = useState(null);
  const [checkAllI, setCheckAllI] = useState(false);
  useEffect(() => {
    if (checkAllI) {
      setCheckAll(true)
    }
    else setCheckAll(false)
  }, [checkAllI])

  useEffect(() => {
    let arrSearch = []
    if (permissionManage?.search?.getLink == 1) {
      arrSearch.push('getLink')
    }
    if (permissionManage?.search?.export == 1) {
      arrSearch.push('export')
    }
    if (arrSearch.length == 2) setCheckAllI(true)
    setCheckAll(permissionManage?.fullAccess == 1 ? true : false)
    setCheckedListI([...arrSearch])

  }, [])

  const onChange = async (list, type) => {
    // if (type === 'dashboard') {
    //   setCheckedList(list.target.checked);
    //   setCheckAll(list.length === options.length);
    // } else {
    setCheckedListI(list);
    setCheckAllI(list.length === optionsI.length);
    handleSelectRole({
      ...permissionManage,
      search: {
        getLink: list?.includes('getLink') ? 1 : 2,
        export: list?.includes('export') ? 1 : 2,
      },
    })
    // }
  };

  const onCheckAllChange = (checked, type) => {
    let list = []
    let list1 = []
    if (type === 'all') {
      options?.map(item => list.push(item?.value))
      setCheckedList(checked ? true : false);
      setCheckAll(checked);
      optionsI?.map(item => list1.push(item?.value))
      setCheckedListI(checked ? list1 : []);
      setCheckAllI(checked);
    } else {
      optionsI?.map(item => list.push(item?.value))
      setCheckedListI(checked ? list : []);
      setCheckAllI(checked);
    }
    handleSelectRole({
      fullAccess: checked ? 1 : 2,
      search: {
        getLink: checked ? 1 : 2,
        export: checked ? 1 : 2,
      },
    })
  };

  return (
    <Item>
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <Grid
        style={{
          padding: '20px 0 30px 30px',
        }}
        id='manager-global-care'
        container
      >
        <Grid style={{ borderRight: '1px solid #cecece' }} pr={1}>
          <Checkbox
            key={1}
            checked={checkAll}
            onChange={(e) => onCheckAllChange(e.target.checked, 'all')}
          >
            Toàn quyền
          </Checkbox>
          {/* <br/>
          <Checkbox
            checked={checkedList}
            onChange={(e) => onChange(e, 'dashboard')}
          >
          Dashboard
          </Checkbox> */}
        </Grid>

        <Grid pl={2}>
          <Checkbox
            key={2}
            onChange={(e) => onCheckAllChange(e.target.checked, 'search')}
            checked={checkAllI}
          >
            Kiểm tra xử lý hồ sơ
          </Checkbox>
          <Checkbox.Group
            style={{
              display: 'grid',
              padding: '0px 0 15px 44px',
            }}
            options={optionsI}
            value={checkedListI}
            onChange={(e) => onChange(e, 'search')}
          />
        </Grid>
      </Grid>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));
