import React from 'react';
import {
    Container,
    Modal,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    conmodal: {
        alignItems: 'center',
        display: 'flex',
        position: 'fixed',
        zIndex: 1300,
        inset: '0px',
        padding: '20px',
        justifyContent: 'center',

    },
}));
export default function ModalLoading(props) {
    const classes = useStyles();
    return (
        <Modal
            open={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={clsx(classes.conmodal)}
        >
            <Container style={{border:'none', outline:'none'}}>
                <div style={{ textAlign: 'center', paddingBottom: 100, color: 'white', fontSize: 20, }}>
                    {props.content}
                </div>
                <div className="container-loading">
                    <div className="dash uno"></div>
                    <div className="dash dos"></div>
                    <div className="dash tres"></div>
                    <div className="dash cuatro"></div>
                </div>
            </Container>
        </Modal>

    );
}