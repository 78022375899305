
import { useEffect, useState } from 'react';
import { Box, Container, Button, Stack, Typography } from '@mui/material';
import {
  Link, useHistory,
} from "react-router-dom";
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconAccount from "../assets/icons/iconAccount.svg"
import { AccountTable } from '../components/account-manage/table-list';
import { AccountManagerSearch } from '../components/account-manage/search';
import { getListAccountGlobalcare, getListAccountFromProvider } from '../../../../apis/adminManagerAccountApis'
import * as PATH from '../../../../configs/routesConfig';


const AccountManage = () => {
  const [productCode, setProductCode] = useState();
  const [render, setRender] = useState(false)
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [dataFilter, setDataFilter] = useState(false)
  const user = localStorage.getItem('user') || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0
  const roleAccount = (isCheckUser && JSON.parse(user)?.role_management_claim?.account) || 0

  useEffect(() => {
    fetchListAccountFromGlobalCare({})
  }, [])

  const fetchListAccountFromGlobalCare = (params) => {
    setIsLoading(true)
    getListAccountGlobalcare(params).then(res => {
      const data = res?.data?.result?.data
      const total = res?.data?.result?.total || 0
      setListData(data)
      setIsLoading(false)
    })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
      })
  }
  
  const handleSearch = (dataSearch) => () => {
    if (!dataSearch?.username && dataSearch?.username == '') delete dataSearch.username;
    if (!dataSearch?.fullname && dataSearch?.fullname == '') delete dataSearch.fullname;
    if (!dataSearch?.email && dataSearch?.email == '') delete dataSearch.email;
    if (!dataSearch?.roleId && dataSearch?.roleId == '') delete dataSearch.roleId;
    if (!dataSearch?.status && dataSearch?.status == '') delete dataSearch.status;
    fetchListAccountFromGlobalCare(dataSearch)
    setDataFilter(true)
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 8
        }}
      >

        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
          >
            Quản lý tài khoản
          </Typography>
            <Link to={PATH.ADMIN_ACCOUNT_MANAGE_CREATE}>
              <Button
                sx={{ borderRadius: 39, paddingX: 6, paddingY: 1.6 }}
                color="gc"
                variant="contained">
                <img src={IconAccount} /> &nbsp;
                Tạo tài khoản
              </Button>
            </Link>
          <Stack spacing={5}>
            <AccountManagerSearch handleSearch={handleSearch} />
              <AccountTable
                data={listData}
                isLoading={isLoading}
                roleAccount={roleAccount}
                dataFilter={dataFilter}
              />
          </Stack>
        </Container>

      </Box>
    </>
  )
};

export default AccountManage


const styleButton = {
  background: '#E16B2F',
  color: '#fff',
  borderRadius: 39
}
