import { useState, useEffect, useRef } from 'react';
import {
  Link, useHistory
} from "react-router-dom";
import { List, ListItem, ListItemAvatar, ListItemText, Paper, ListItemIcon, Modal, Box, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { format, parseISO } from 'date-fns';
import { v4 as uuid } from 'uuid';
import iconClaimFile from '../../../assets/icons/iconClaimFile.svg'
import { getListNotification, postUpdateFCMToken, postUpdateReadNotification } from '../../../../../../apis/notificationApis';
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import moment from 'moment';

export const ModalNotification = ({ showModalNotification, onClose, setBadgeContent }) => {
  const [notificationList, setNotificationList] = useState([]);
  const history = useHistory()

  const user = localStorage.getItem('user') || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0

  useEffect(() => {
    getListNotification().then((v) => setNotificationList({ ...v.data.result }))

    const firebaseApp = initializeApp({
      apiKey: "AIzaSyBLLhMoJlHX21un3GI0div6pgBHq5BJaMY",
      authDomain: "globalcare-e9e4f.firebaseapp.com",
      databaseURL: "https://globalcare-e9e4f.firebaseio.com",
      projectId: "globalcare-e9e4f",
      storageBucket: "globalcare-e9e4f.appspot.com",
      messagingSenderId: "1021090720883",
      appId: "1:1021090720883:web:b2775d61946b3ab7f44e06",
      measurementId: "G-GB79Z9TBL0"
    });

    const messaging = getMessaging(firebaseApp);

    getToken(messaging).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        postUpdateFCMToken({ 'fcm': currentToken })

      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    onMessage(messaging, (payload) => {
      getListNotification().then((v) => setNotificationList({ ...v.data.result }))
    });
  }, []);

  useEffect(() => {
    setBadgeContent(notificationList?.unread || 0)
  }, [notificationList]);

  const handleClick = (notification, link) => {
    postUpdateReadNotification(notification?.id).then(
      (v) => window.location = `/admin/search/detail/${notification?.license_plates}`
    )

  }

  return (
    <Modal
      open={showModalNotification}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={modalAccount}>
        <Box>
          <Typography
            variant="h6"
            color="white"
            p={1}
          >
            Thông báo
          </Typography>
        </Box>
        <Paper style={{ overflow: 'auto' }}>
          <List sx={{ maxHeight: 340 }}>
            {(notificationList?.notifies || []).map((notification, i) => (
              <ListItem
                style={{ background: !notification?.seen ? '#FFF8F1' : 'white' }}
                onClick={(e) => handleClick(notification, notification?.link)}
                divider={i < notificationList.length - 1}
                key={notification?.id}
              >
                <ListItemText
                  secondary={
                    <div style={{ color: "black" }}>
                      Hồ sơ chụp hình xe:&nbsp;
                      <span>
                        <b>{notification?.license_plates}</b>
                      </span>
                      <br />
                      Biển số xe:&nbsp;
                      <span>
                        <b>
                          {notification?.profile_image}
                        </b>
                      </span>
                      <br />
                      Trạng thái:&nbsp;
                      <span>
                        {notification?.status == 'waiting' && <span style={{ color: "#007DC5" }}>Chờ chụp hình</span>}
                        {notification?.status == 'fail' && <span style={{ color: "#FF0000" }}>BKS chưa khớp</span>}
                        {notification?.status == 'success' && <span style={{ color: "#39B44A" }}>Thành công</span>}
                      </span>
                      <br />
                      <small style={{ color: 'rgba(0, 0, 0, 0.39)' }}>
                        {notification?.status != 'waiting'
                          ?
                          moment(notification?.updated_at).format('hh:mm DD-MM-YYYY')
                          :
                          moment(notification?.created_at).format('hh:mm DD-MM-YYYY')
                        }

                      </small>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Modal>
  )
}

const contants_status = [
  { value: '', label: '', },
  { value: 'new', label: 'Chưa được xử lý', },
  { value: 'inprocess', label: 'Đang xử lý', },
  { value: 'waiting_approve', label: 'Chờ phê duyệt', },
  { value: 'waiting_update', label: 'Bổ sung hồ sơ', },
  { value: 'approved', label: 'Đã phê duyệt', },
  { value: 'completed', label: 'Hoàn thành', },
  { value: 'rejected', label: 'Từ chối', },
]

const modalAccount = {
  position: 'absolute',
  top: '60px',
  right: '10px',
  background: '#FB7800',
  color: 'white',
  borderRadius: '14px',
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.07)"
}
