
import { useEffect, useState, useContext } from 'react';
import { Box, Container, Button, Stack, Typography } from '@mui/material';
import {
  Link,
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { message } from 'antd';

import { AccountInfo } from '../components/account-manage/account-info';
import { AccountGlobalCare } from '../components/account-manage/account-global-care';
import { AccountGara } from '../components/account-manage/account-gara';
import { AccountRole } from '../components/account-manage/account-role';
import { AccountInsuranceSystem } from '../components/account-manage/account-insurance-system';
import { AccountSubMenu } from '../components/account-manage/account-sub-menu';
import { AccountSubClass } from '../components/account-manage/account-sub-class';
import {
  formValidate,
  phoneValidate2,
  emailValidate,
  passwordValidate,
  costValidate,
  requiredValidField
} from '../../../../utils/validation/input'
import {
  createAccountProvider,
  createAccountGlobalCare,
  createAccountMerchant,
  createAccountGara,
  getAccountDetailsFromGlobalCare,
  getAccountDetailsFromProvider,
  updateAccountGara,
  updateAccountGlobalCare,
  updateAccountProvider,
  updateAccountMerchant,
} from '../../../../apis/adminManagerAccountApis';
import { messages } from '../../../../utils/messages';
import * as PATH from '../../../../configs/routesConfig';
import { ModalLoading } from '../../../../ui/modal';

const manager = [
  { value: 1, label: 'Công ty bảo hiểm', },
  { value: 2, label: 'Merchant', },
  { value: 3, label: 'Global Care ', },
  { value: 4, label: 'Bệnh viện phòng khám', },
]

const tomatoProviders = [3, 7];

const initListData2 = [
  { label: 'MIC', value: '1' },
  { label: 'PTI', value: '2' },
  { label: 'VNI', value: '3' },
]
const initListData = [
  { label: 'Global Care', value: '1' },
  { label: 'Công ty bảo hiểm', value: '2' },
  { label: 'Merchant', value: '3' },
]

const AccountCreate = (props) => {
  const history = useHistory()
  const location = useLocation();
  const userId = location?.state?.userId || false
  const roleEdit = location?.state?.roleEdit || false
  const user = JSON.parse(localStorage.getItem('user')) || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && user?.extra_info?.userType) || 0
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [productCode, setProductCode] = useState(null);
  const [render, setRender] = useState(false);
  const [accountDetails, setAccountDetails] = useState({})
  const validationSchema = (values) => yup.object().shape({
    fullname: formValidate,
    username: formValidate && yup.string().min(6, 'Vui lòng nhập ít nhất 6 ký tự'),
    email: emailValidate,
    phone: phoneValidate2,
    password: !userId && passwordValidate,
    newPassword: isChangePassword && userId && passwordValidate,
    roleId: formValidate,
    insuranceIncId: values.roleId == 2 && formValidate,
  });

  const initialValues = {
    fullname: '',
    username: '',
    email: '',
    phone: '',
    manager: '',
    password: '',
    newPassword: '',
    agencyName: '',
    garage: '',
    lastLogin: '',
    approvalValueFrom: '',
    approvalValueTo: '',
    system_approvalValueFrom: '',
    system_approvalValueTo: '',
    levelId: '',
    insuranceIncId: '',
    roleId: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: props => yup.lazy(validationSchema),
    onSubmit: (values) => {
      handleSubmitData(values)
    },
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setSubmitting,
    isSubmitting,
    setValues
  } = formik

  useEffect(() => {
  }, [])

  const [roleSubMenu, setRoleSubMenu] = useState([]);

  const [errorSelectGara, setErrorSelectGara] = useState('')
  const [errorSubMenu, setErrorSubMenu] = useState('')
  const [permissionAccount, setPermissionAccount] = useState()
  const [permissionManage, setPermissionManage] = useState()
  
  const handleSelectRoleAccount = (e) => {
    setPermissionAccount({
      create_edit: e?.includes('create_edit') ? 1 : 2,
      export: e?.includes('export') ? 1 : 2,
    })

  }

  const handleSetPermissionManage = (e) => {
    setPermissionManage(e)
  }

  // const handleSelectGara = (e) => {
  //   setDepartmentId(e)
  // }

  const validateSubmenuAllNull = () => {
    return true
    // let i = 0
    // let isCheck = false
    // for (let v of Object.values(roleSubMenu)) {
    //   if (
    //     (v['search'] && Object.values(v['business_report']).indexOf(1) > -1) ||
    //     (v['claim_approval'] && Object.values(v['claim_approval']).indexOf(1) > -1) ||
    //     (v['claim_report'] && Object.values(v['claim_report']).indexOf(1) > -1) ||
    //     (v['dashboard'] && Object.values(v['dashboard']).indexOf(1) > -1) ||
    //     v['full_access'] === 1
    //   ) {
    //     isCheck = true
    //   } else {
    //     roleSubMenu.splice(i, 1);
    //     isCheck = false
    //   }
    //   i += 1
    // }
    // return isCheck
  }

  const handleSubmitData = async () => {
    console.log('handleSubmitData', 123122)
    const {
      fullname,
      username,
      email,
      phone,
      password,
      manager,
      agencyName,
      insuranceIncId,
      roleId
    } = values
    if (validateSubmenuAllNull()) {
      // ModalLoading.show()
      let params = {
        username,
        password,
        fullname,
        email,
        phone_number: phone,
        role_id: 9,
        extra_info: {
          merchant_ai: {
            roleId,
            roleName: initListData[roleId-1].label,
            insuranceIncName: insuranceIncId ? initListData2[insuranceIncId-1].label : '',
            insuranceIncId,
            agencyName,
            level: user?.extra_info?.merchant_ai?.level + 1 || 1,
            permission: {
              portalAI: {
                ...permissionManage,
                account: permissionAccount
              },
            }
          },
        }
      }
      createAccountGara(params).then(res => {
        updateSuccess()
      })
        .catch(err => {
          updateFail(err?.response?.data)
        })
    } else {
      setSubmitting(false)
    }
  }

  const updateSuccess = async () => {
    setSubmitting(false);
    ModalLoading.hide()
    history.push(PATH.ADMIN_ACCOUNT_MANAGE)
    message.success({
      content: userId ?
        messages.alert.update_account_success :
        messages.alert.create_account_success,
      duration: 5
    })
  }

  const updateFail = (error) => {
    ModalLoading.hide()
    setSubmitting(false);
    message.error({
      content: userId ?
        messages.alert.update_account_error :
        messages.alert.create_account_error,
      duration: 5
    })
  }


  return (
    <>
      {
        <form onSubmit={handleSubmit}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pt: 8
            }}
          >
            <Container maxWidth="lg" style={{ marginBottom: 50 }}>
              <Typography
                sx={{ mb: 3 }}
                variant="h4"
              >
                Tạo tài khoản
              </Typography>
              <Typography>
                Vui lòng nhập các trường thông tin để tạo tài khoản
              </Typography>
              <AccountInfo
                setValues={setValues}
                values={values}
                data={manager}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                systemType={systemType}
                errorSelectGara={errorSelectGara}
                userId={userId}
                roleEdit={roleEdit}
                setIsChangePassword={setIsChangePassword}
              />

              <AccountGara
                title='Quyền Quản Lý'
                handleSelectRole={handleSetPermissionManage}
                userId={userId}
                roleData={accountDetails?.role_management_claim?.submenu}
                roleEdit={roleEdit}
              />
              {(user?.extra_info?.merchant_ai?.level || 0) < 2 && <AccountRole
                handleSelectRole={handleSelectRoleAccount}
                title='Quyền Quản Lý Tài Khoản'
                userId={userId}
                roleEdit={roleEdit}
                roleData={accountDetails?.role_management_claim?.account}
              />
              }
              {errorSubMenu ? (
                <ErrorSubMenu>{errorSubMenu}</ErrorSubMenu>
              ) : null}
              <ButtonBottom
                type='submit'
                color="gc"
                // loading={isSubmitting}
                variant="contained"
                style={{ height: 56, width: 246 }}
                loadingPosition="start"
              // disabled={isSubmitting}
              >
                Tạo tài khoản
              </ButtonBottom>
              <div style={{ height: 100 }} />
            </Container>
          </Box>
        </form>
      }
    </>

  )
};

const ButtonBottom = styled(LoadingButton)(() => ({
  height: 56,
  width: 246,
  margin: 'auto',
  display: 'flex',
  top: 60,
}));

const ErrorSubMenu = styled(Typography)(() => ({
  fontSize: 16,
  color: 'red',
  textAlign: 'center',
  marginTop: 20
}));

export default AccountCreate
