import { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import './account-manager.css'

export const AccountGlobalCare = ({
  handleSelectRole = () => {},
  title = '',
  roleAccount = [],
  roleMechant = [],
  roleProduct = [],
  roleProvider = [],
  mechant,
  product,
  provider,
  userId = null,
  roleEdit = false
}) => {
  const options = [
    { label: 'Tra cứu', value: 'search' },
    { label: 'Tạo và quản lý tài khoản', value: 'view_and_edit' },
    { label: 'Xuất báo cáo', value: 'export' },
  ];
  const [checkedList, setCheckedList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const checkedMechant = roleMechant.full_access === 1 ? true : false
  const checkedProduct = roleProduct.full_access === 1 ? true : false
  const checkedProvider = roleProvider.full_access === 1 ? true : false

  useEffect(() => {
    if(userId) {
      let roleList = []
      let list = Object.keys(roleAccount)
      list?.map(v => {
        if(roleAccount[v] !== 0) {
          roleList.push(v)
        }
      })
      handleSelectRole('product', checkedProduct)
      handleSelectRole('provider', checkedProvider)
      handleSelectRole('mechant', checkedMechant)
      onChange(roleList)
    }
  },[userId, roleAccount, roleMechant, roleProduct, roleProvider])

  const onChange = list => {
    setCheckedList(list);
    if(list.length === options.length) {
      handleSelectRole('account', list, true)
    } else {
      handleSelectRole('account', list, false)
    }
    setCheckAll(list.length >= options.length);
  };

  const onCheckAllChange = e => {
    let list = []
    const data = e.target.checked ? list : []
    options?.map(item => list.push(item?.value))
    setCheckedList(e.target.checked ? list : []);
    setCheckAll(e.target.checked);
    handleSelectRole('account', data, e.target.checked)
  };

  return (
    <Item>
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <Grid
        style={{
          padding: '50px 0 10px 30px',
          borderBottom: '1px solid #E1E1E1',
          marginLeft: 0,
        }}
        container
        spacing={3}
        xs={12}
        id='manager-global-care'
      >
        <Grid xs={4}>
          <Checkbox
            onChange={e => handleSelectRole('product', e.target.checked)}
            checked={product}
            disabled={userId && !roleEdit}
          >
            Quản lý sản phẩm
          </Checkbox>
        </Grid>
        <Grid xs={4}>
          <Checkbox
            onChange={e => handleSelectRole('provider', e.target.checked)}
            checked={provider}
            disabled={userId && !roleEdit}
          >
            Quản lý Công ty bảo hiểm
          </Checkbox>
        </Grid>
        <Grid xs={4}>
          <Checkbox
            onChange={e => handleSelectRole('mechant', e.target.checked)}
            checked={mechant}
            disabled={userId && !roleEdit}
          >
            Quản lý kênh
          </Checkbox>
        </Grid>
      </Grid>
      <Grid
        style={{
          padding: '20px 0 30px 30px',
        }}
        id='manager-global-care'
      >
        <Checkbox
          onChange={onCheckAllChange}
          checked={checkAll}
          disabled={userId && !roleEdit}
        >
          Quản lý tài khoản
        </Checkbox>
        <Checkbox.Group
          style={{
            display: 'grid',
            padding: '0px 0 15px 44px',
          }}
          options={options}
          value={checkedList}
          onChange={onChange}
          disabled={userId && !roleEdit}
        />
      </Grid>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));
