import React, { useEffect, useState } from 'react';
import './styles/global.css';
import {
    Modal,
    Box,
    Button
} from '@mui/material';
import api from "../../../../configs/api";
import OptionVCX from './components/vcx/index';
import moment from 'moment';
import ModalSuccess from './commons/modalSucces';
import './style.css'
import { getParamUrlByKey } from "../../../../utils/utils";

const initValuePackage = {
    '4': { checked: false, duration: 3, key: undefined },   // TNDS
    '27': { checked: false, duration: undefined, code: undefined, bks: undefined },   // VCX
}

const initVehicleInfo = {
    'tab': 'license'
}

const arrNumberOfSeatsOrTonnageName = [
    { label: 'Xe dưới 3 tấn', value: 1 },
    { label: 'Xe từ 3 đến 8 tấn', value: 2 },
    { label: 'Xe trên 8 đến 15 tấn', value: 3 },
    { label: 'Xe trên 15 tấn', value: 4 },
]


const initImageList = {
    invoice: '',
    damaged: {},
    captured: {},
}


export default function HomeIndex(props) {
    const [stepParent, setStepParent] = useState(1);
    const [checkLicense, setCheckLicense] = useState(false);
    const [license, setLicense] = useState(true);
    const [licenseAI, setLicenseAI] = useState(true);
    const [insuredInfo, setInsuredInfo] = useState({})
    const [vehicleInfo, setVehicleInfo] = useState(initVehicleInfo)
    const [loading, setLoading] = useState(true)
    const [infoVat, setInfoVat] = useState({})
    const [isShowIframeAI, setIsShowIframeAI] = useState(false)
    const [message, setMessage] = useState([])
    const [imageCapturedList, setImageCapturedList] = useState();
    const [imageDamagedList, setImageDamagedList] = useState({});
    const [imageCustomList, setImageCustomList] = useState({});
    const [imageWindshieldList, setImageWindshieldList] = useState([]);
    const [imageSpeedometerList, setImageSpeedometerList] = useState([]);
    const [imageDamagedDetection, setImageDamagedDetection] = useState();
    const [imageInvoice, setImageInvoice] = useState([]);
    const [feesDetail, setFeesDetail] = useState({});
    const [numberUploadImages, setNumberUploadImages] = useState(0);
    const [folderName, setFolderName] = useState(null);
    const [choosePackage, setChoosePackage] = useState(initValuePackage);
    const [valuesOCR, setValuesOCR] = useState({});
    const [propsModalSuccess, setPropsModalSuccess] = useState({
        show: false,
        code: null,
        choosePackage: choosePackage
    });
    const [valueCommission, setValueCommission] = useState(0)
    const [error, setError] = useState(true)

    // useEffect(() => {
    //     window.addEventListener(
    //         "message",
    //         ({ data }) => setMessage({ ...data })
    //     );
    //     checkStatus();
    // }, [])

    useEffect(() => {
        let arrIndexFieldError = []
        if (!imageCapturedList?.frontLeft || !imageCapturedList?.backLeft || !imageCapturedList?.frontRight || !imageCapturedList?.backRight) {
            arrIndexFieldError.push('imageVCX1')
        }
        if (arrIndexFieldError.length === 0) setError(false)
        else setError(true)
    });

    useEffect(() => {
        const constantKeyImage = []
        window.addEventListener(
            "message",
            ({ data }) => handleMessages(data, constantKeyImage)
        );
        checkStatus();
    }, [])

    const validateLicense = (listLicense, license) => {
        let validateLicense = true
        for (let i=0;i<listLicense.length;i++){
            if (listLicense[i]?.keyPosition == 'other') continue
            if (listLicense[i]?.plateData.toLowerCase() != license.replace('-', '').replace('.', '').toLowerCase()){
                validateLicense = false
            }
        }
        return validateLicense
    }


    const handleMessages = (message, constantKeyImage) => {
        switch (true) {
            case message?.type == 'summary':
                let license = localStorage.getItem('privateKey') || ''
                let arrKeyImage = [];
                let arrLicenseAI = []
                resetStateImageAI();
                (message?.message || []).map((v, i) => {
                    arrKeyImage.push(v?.keyPosition)
                    arrLicenseAI.push({ [v?.keyPosition]: v?.plateData.toLowerCase() })
                })
                constantKeyImage.push(...arrKeyImage)
                setCheckLicense(validateLicense(message?.message, license))
                setLicenseAI(arrLicenseAI)
                uploadStepSummary(message?.message);
                return;

            case message?.type == 'damaged-detection':
                uploadDamagedDetection(message?.message, constantKeyImage);
                return;

            case message?.type == 'damaged-sumary':
                uploadStepDamagedSummary(message?.message, constantKeyImage);
                return;

            case message?.type == 'thankyou':
                setIsShowIframeAI(false);
                return;

            default:
                return;
        }
    }


    const checkStatus = async () => {
        localStorage.removeItem('privateKey')
        try {
            const response = await api.apiForm('GET', `/api/v1/mc-ai/verify-link?code=${getParamUrlByKey('code')}`)
            if (response.data.statusCode !== 400) {
                setLicense(response?.data?.result?.privateKey || 'done')
                localStorage.setItem('privateKey', response?.data?.result?.privateKey)
            }
        } catch (e) {
            if (e.toString().includes('400')) {
                return setLicense('done')
            } else {
                return setLicense(false)
            }
        }
    }

    const resetStateImageAI = () => {
        setImageCapturedList({})
        setImageCustomList({})
        setImageDamagedDetection({})
        setImageDamagedList({})
    }

    const uploadStepSummary = async (message) => {
        let objCaptured = {};
        for (let i = 0; i < message.length; i++) {
            let _blob = await ImageDataToFile(message[i].image);
            let _file = new File([_blob], `capture_${message[i].keyPosition}.png`)
            let linkImage = await uploadImageApi(_file)
            objCaptured = { ...objCaptured, [message[i].keyPosition]: linkImage };
        }
        setImageCapturedList({ ...objCaptured })
        return;
    }


    const uploadStepDamagedSummary = async (message, constantKeyImage) => {
        let listDamagedImage = message?.damagedData?.damagedImage;
        let listcustomCapture = message?.damagedData?.customCapture;
        let listDamagedImageUploaded = []
        let listCustomImageUploaded = []

        await Promise.all((listDamagedImage || []).map(async (v, i) => {
            let _blob = await ImageDataToFile(v?.damagedImageCapturted);
            let _file = new File([_blob], `capture_damaged_${constantKeyImage[message.damagedState]}_${i}.png`)
            let linkImage = await uploadImageApi(_file);
            let object = { url: linkImage, userConfirm: v?.userConfirmDamaged }
            listDamagedImageUploaded.push(object)
        })).then(() => {
            setImageDamagedList((imageDamagedList) => {
                return {
                    ...imageDamagedList,
                    [constantKeyImage[message.damagedState]]: listDamagedImageUploaded
                }
            });
        })
        await Promise.all((listcustomCapture || []).map(async (v, i) => {
            let _blob = await ImageDataToFile(v);
            let _file = new File([_blob], `capture_custom_${constantKeyImage[message.damagedState]}_${i}.png`)
            let linkImage = await uploadImageApi(_file)
            listCustomImageUploaded.push(linkImage)
        })).then(() => {
            setImageCustomList((imageCustomList) => {
                return {
                    ...imageCustomList,
                    [constantKeyImage[message.damagedState]]: listCustomImageUploaded
                }
            });
        })
        /// PASS
        setLoading(false)
    }

    const uploadDamagedDetection = async (message, constantKeyImage) => {
        if (message?.countDamagedDetection > 0) {
            let aiDamagedDetection = message?.aiDamagedDetection;
            let linkImageDamagedDetection = '';
            if (aiDamagedDetection && aiDamagedDetection != '') {
                let _blob = await ImageDataToFile(aiDamagedDetection);
                let _file = new File([_blob], `damaged_detection_${constantKeyImage[message.damagedState]}.png`)
                linkImageDamagedDetection = await uploadImageApi(_file)
                setImageDamagedDetection((imageDamagedDetection) => {
                    return {
                        ...imageDamagedDetection,
                        [constantKeyImage[message.damagedState]]: linkImageDamagedDetection
                    }
                });

            }
            return;
        }
    }

    const ImageDataToFile = async (imageData) => {
        let w = imageData?.width || 1920;
        let h = imageData?.height || 1080;
        let canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        let ctx = canvas.getContext("2d");
        ctx.putImageData(imageData, 0, 0);

        return await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 0.7))
            .catch((err) => console.log('err', err));
    }

    const uploadImageApi = async (_files, name) => {
        const data = new FormData();

        data.append('storage', 's3');
        data.append('file', _files);
        data.append('path', `/vat-chat-xe/mc/${getParamUrlByKey('code')}`);
        const response = await api.apicall_g2_formData('post', '/upload', data)
        if (response?.data?.complete) {
            return response?.data?.link
        }
        return false
    }

    const createOrder = (choosePackage, valueCode) => {
        const data = {
            extra_info: {
                licenses: licenseAI,
                invoice: imageInvoice,
                imageSpeedometerList: imageSpeedometerList,
                imageWindshieldList: imageWindshieldList,
                damaged: imageDamagedList,
                captured: imageCapturedList,
                manualDamaged: imageCustomList,
                damagedDetection: imageDamagedDetection,
            },
            status: checkLicense ? 2 : 3,
        }
        return data;
    }

    const orderAction = async () => {
        const data = { data: window.btoa(JSON.stringify(createOrder())) }
        const response = await api.apiForm_formData('post', `/api/v1/mc-ai/${getParamUrlByKey('code')}/update`, data)
        if (response.data.statusCode !== 400) {
            window.location.reload();
        }
    }

    const canvasToHTML = (imageData) => {
        if (imageData) {
            let w = imageData?.width || 1920;
            let h = imageData?.height || 1080;
            let canvas = document.createElement("canvas");
            canvas.width = w;
            canvas.height = h;
            let ctx = canvas.getContext("2d");
            ctx.putImageData(imageData, 0, 0);

            const dataURI = canvas.toDataURL()
            return dataURI || undefined;
        }
    }

    const childProps = {
        setError: setError,
        error: error,
        orderAction: orderAction,
        choosePackage: choosePackage,
        stepParent: stepParent,
        insuredInfo: insuredInfo,
        vehicleInfo: vehicleInfo,
        folderName: folderName,
        numberUploadImages: numberUploadImages,
        valuesOCR: valuesOCR,
        feesDetail: feesDetail,
        valueCommission: valueCommission,
        isShowIframeAI: isShowIframeAI,
        imageCapturedList: imageCapturedList,
        imageCustomList: imageCustomList,
        imageDamagedList: imageDamagedList,
        imageInvoice: imageInvoice,
        message: message,
        imageDamagedDetection: imageDamagedDetection,
        loading: loading,
        infoVat: infoVat,
        imageWindshieldList: imageWindshieldList,
        imageSpeedometerList: imageSpeedometerList,
        setInfoVat: setInfoVat,
        setLoading: setLoading,
        canvasToHTML: canvasToHTML,
        setValueCommission: setValueCommission,
        setValuesOCR: setValuesOCR,
        setFolderName: setFolderName,
        setNumberUploadImages: setNumberUploadImages,
        setStepParent: setStepParent,
        setInsuredInfo: setInsuredInfo,
        setVehicleInfo: setVehicleInfo,
        setChoosePackage: setChoosePackage,
        setFeesDetail: setFeesDetail,
        setIsShowIframeAI: setIsShowIframeAI,
        uploadImageApi: uploadImageApi,
        setImageCapturedList: setImageCapturedList,
        setImageDamagedList: setImageDamagedList,
        setImageCustomList: setImageCustomList,
        setImageWindshieldList: setImageWindshieldList,
        setImageSpeedometerList: setImageSpeedometerList,
        setImageDamagedDetection: setImageDamagedDetection,
        setImageInvoice: setImageInvoice,
        setMessage: setMessage,
    }


    return (
        <>

            {
                license && license != 'done' ?
                    <>
                        <div className='app container mobi'>
                            <OptionVCX {...childProps} />;
                            <ModalSuccess {...propsModalSuccess} setOpen={setPropsModalSuccess} />
                        </div>
                        <iframe
                            key='iframe_ai'
                            style={{ display: !isShowIframeAI ? 'none' : 'block', position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%', border: 'none', margin: 0, padding: 0, overflow: 'hidden', zIndex: '999999' }}
                            src={`https://car-img-dev.globalcare.vn?license=${getParamUrlByKey('code')}`}
                            width="100%"
                            height="100vh"
                            allow="camera; microphone"
                        />
                    </>
                    : <Box height="100vh" display="flex" justifyContent="center" alignItems="center" fontSize={23} >
                        {license == 'done' ? "Chụp hình hoàn tất" : "Link hết hạn"}
                    </Box>
            }

        </>

    );
}

const style = {
    padding: "1rem",
    position: 'absolute',
    border: 'none',
    borderRadius: "8px",
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90vw",
    background: 'white',
    zIndex: 1
};

