// DEV
const SERVER = "https://core-dev.globalcare.vn";
const CORE_SERVER = "https://core-dev.globalcare.vn";
const G2Domain="https://g2-dev.globalcare.vn";

// BETA
// const SERVER = "https://claim-api-beta.globalcare.vn";
// const CORE_SERVER = "https://core-beta.globalcare.vn";

// LIVE
// const SERVER = 'https://claim-api.globalcare.vn';
// const CORE_SERVER = "https://core.globalcare.vn";

// DEV`
// const SERVER = 'http://localhost:6966';
// const CORE_SERVER = "https://core-dev.globalcare.vn";

const exportedObject = {
  SERVER,
  CORE_SERVER,
  G2Domain
};

export default exportedObject;
