import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import './account-manager.css'

export const AccountRole = ({
  handleSelectRole = () => {},
  title = '',
  roleData = [],
  userId = null,
  roleEdit = false,
  permissionAccount = null
}) => {
  const options = [
    { label: 'Tạo và quản lý tài khoản', value: 'create_edit' },
    { label: 'Xuất báo cáo', value: 'export' },
  ];
  const [checkedList, setCheckedList] = useState(null);
  useEffect(() => {
    let arrPermissionAccount = []
    if (permissionAccount && !checkedList){
      if (permissionAccount?.create_edit == 1){
        arrPermissionAccount.push('create_edit')
      }
      if (permissionAccount?.export == 1){
        arrPermissionAccount.push('export')
      }
      setCheckedList([...arrPermissionAccount])
    }
  },[permissionAccount])

  const onChange = list => {
    setCheckedList(list)
    handleSelectRole(list)
  };

  return (
    <Item
      id='manager-global-care'
    >
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <Grid
        style={{
          padding: '50px 0 10px 30px',
          borderBottom: '1px solid #E1E1E1',
          marginLeft: 0,
        }}
        container
        spacing={3}
        xs={12}
        id='manager-role'
      >
        <Checkbox.Group
          style={{
            padding: '0px 0 15px 0px',
            width: '100%',
            display: 'flex'
          }}
          options={options}
          value={checkedList}
          onChange={onChange}
        />
      </Grid>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));
