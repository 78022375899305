import {
  Link, useHistory
} from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoginLayout } from '../components/layout-login/login-layout';
import { forgotPWD } from '../../../../apis/authApis'
import { message } from 'antd';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import iconPhone from '../assets/icons/iconPhone.svg';

const ForgotPassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .max(255)
        .required(
          'Username is required'),
    }),
    onSubmit: (values) => {
      forgotPWD(values).then((v) => {
        if (v.data.statusCode == 200) {
          message.success({
            content: v.data.message,
            duration: 5
          })
          history.push(`change-password/${values.username}`);
        }
        else {
          message.error({
            content: v.data.message,
            duration: 5
          })
        }
        formik.setSubmitting(false)
      }).catch((e) => {
        message.error({
          content: "Mật khẩu trước đó không đúng.",
          duration: 5
        })
        formik.setSubmitting(false);
      })
    }
  });

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexFlow: 'column',
          flexGrow: 1,
          minHeight: '100%',
          background: '#FAFAFA'
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: 'auto' }}>
        <Tooltip title="Back" style={{ borderRadius: 5 }}>
            <IconButton
              onClick={() => history.goBack()}
            >
              <KeyboardBackspaceIcon /> &nbsp;
            </IconButton>
          </Tooltip>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Typography
                fontWeight="fontWeightBold"
                variant="h4"
              >
                Quên mật khẩu
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography
              >
                Chúng tôi sẽ gủi lại mật khẩu qua email.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.username && formik.errors.username)}
              fullWidth
              helperText={formik.touched.username && formik.errors.username}
              label="Username"
              margin="normal"
              name="username"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="username"
              value={formik.values.username}
              variant="outlined"
              focused
            />
            <Box sx={{ py: 2, mt: 7 }}>
              <Button
                color="gc"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Tiếp tục
              </Button>
            </Box>
          </form>
        </Container>
        <Box
          textAlign="center"
          mt="auto"
          mx="auto"
          pb={3}
        >
          <Typography sx={{ color: '#1E6BE5' }} mb={1}>
            <img src={iconPhone} />
            Hotline: 1900 2929 87
          </Typography>
          <Typography>
            Một sản phẩm công nghệ của Global Care
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ForgotPasswordPage = () => (
  <LoginLayout>
    <ForgotPassword />
  </LoginLayout>
);

export default ForgotPasswordPage;
