export const APP_PATH = "/";
export const ADMIN_PATH = "/admin";

//CLAIM
export const LIST_CLAIM_PATH = "/claim/list";
export const YOUR_CLAIM_LIST_PATH = "/claim/your-claim-list";

// claim car accident path
export const INSU_INFO_CLAIM_ACCIDENT_PATH = "/claim/car-accident/info";
export const CREATE_CLAIM_ACCIDENT_PATH = "/claim/car-accident/create";
export const SUCCESS_CLAIM_ACCIDENT_PATH = "/claim/car-accident/success";
export const SELECT_GARA_PATH = "/claim/car-accident/select-gara";
export const SELECT_GARA_OTHER_PATH = "/claim/car-accident/select-gara-other";
export const SUMMARY_CLAIM_ACCIDENT_PATH = "/claim/car-accident/summary";
export const LIST_CLAIM_CAR_PATH = "/claim/car-accident/list";
export const DETAILS_CLAIM_CAR_PATH = "/claim/car-accident/details/:id";

// claim Tomato insurance path
export const CLAIM_TOMATO = "/claim/tomato";
export const CREATE_REQUEST_CLAIM_TOMATO = "/claim/tomato/create";
export const LIST_CLAIM_TOMATO = "/claim/tomato/create/list-claim";
export const CREATE_CLAIM_FILE_TOMATO = "/claim/tomato/create/claim-file";
export const CREATE_DETAIL_CLAIM_FILE_TOMATO =
  "/claim/tomato/create/claim-file-detail";
export const SHOW_DETAIL_CLAIM_TOMATO = "/claim/tomato/claim-file-detail/show";
export const INSU_TOMATO_CLAIM_LIST = "/claim/tomato/list";
export const INSU_TOMATO_CLAIM_DETAIL = "/claim/tomato/detail/:id";
export const INSU_TOMATO_CLAIM_UPDATE = "/claim/tomato/update/:id";
export const CLAIM_BENEFICIARY_LIST = "/claim/tomato/beneficiaryList";

// claim PHUC BINH AN
export const CLAIM_PHUCBINHAN = "/claim/phucbinhan";

//  admin
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_SEARCH = "/admin/search";
export const ADMIN_SEARCH_GETLINK = "/admin/search/getlink";
export const ADMIN_FORGOT_PASSWORD = "/admin/forgot-password";
export const ADMIN_CHANGE_PASSWORD = "/admin/change-password/:username";
export const ADMIN = "/admin";
export const ADMIN_HOME = "/admin/home";
export const ADMIN_ACCOUNT = "/admin/account";
export const ADMIN_PRODUCT_MANAGE = "/admin/product/manage";
export const ADMIN_COMPANY_MANAGE = "/admin/company/manage";
export const ADMIN_MERCHANT_MANAGE = "/admin/merchant/manage";
export const ADMIN_ACCOUNT_MANAGE_CREATE = "/admin/manage/account/create";
export const ADMIN_ACCOUNT_MANAGE_UPDATE = "/admin/manage/account/update/:id";
export const ADMIN_DASHBOARD = "/admin/dashboard";
export const ADMIN_SEARCH_DETAIL = "/admin/search/detail/:code";

export const ADMIN_ACCOUNT_MANAGE = "/admin/manage/account";
export const ADMIN_ACCOUNT_CREATE = "/admin/accoun-manage/create";
export const ADMIN_ACCOUNT_UPDATE = "/admin/account-manage/update/:id";

// export const ADMIN_CONTRACT = '/admin/contract';
// export const ADMIN_CONTRACT_DETAIL = '/admin/contract/details/:id';
// export const ADMIN_CONTRACT_UPDATE = '/admin/contract/update/:id';
// export const ADMIN_BUSINESS_REPORT = '/admin/business-report';
// export const ADMIN_BUSINESS_REPORT_DETAIL = '/admin/business-report/detail';
// export const ADMIN_BUSINESS_REPORT_UPDATE = '/admin/business-report/update/:id';
// export const ADMIN_CLAIM_REPORT = '/admin/claim-report';
// export const ADMIN_CLAIM_REPORT_DETAIL = '/admin/claim-report/details/:id';
// export const ADMIN_CLAIM_REPORT_UPDATE = '/admin/claim-report/update';
// export const ADMIN_CLAIM_APPROVE = '/admin/claim-approve';
// export const ADMIN_CLAIM_APPROVE_DETAIL = '/admin/claim-approve/details/:id';
// export const ADMIN_CLAIM_APPROVE_UPDATE = '/admin/claim-approve/update/:id';

// Bao Lanh Vien Phi
export const BLVP_RECORDS_DETAIL = "/admin/records-detail";
export const BLVP_RECORDS_DETAIL2 = "/admin/records-detail2";
export const BLVP_RECORDS_MANAGE = "/admin/records-manage";
export const BLVP_RECORDS_MANAGE_DETAIL = "/admin/records-manage/detail";
export const BLVP_RECORDS_CREATE = "/admin/records-manage/create";
export const BLVP_RECORDS_CREATE_DETAIL = "/admin/records-manage/create/detail";
export const BLVP_REPORT = "/admin/report";
export const BLVP_REPORT_DETAIL = "/admin/report/detail";
export const BLVP_DASHBOARD = "/admin/guarantee_dashboard";

//BH-VCOTO

export const ADMIN_CONTRACT = "/admin/contract";
export const ADMIN_CONTRACT_DETAIL = "/admin/contract/details/:id";
export const ADMIN_CONTRACT_UPDATE = "/admin/contract/update/:id";
export const ADMIN_BUSINESS_REPORT = "/admin/business-report";
export const ADMIN_BUSINESS_REPORT_DETAIL = "/admin/business-report/detail/:id";
export const ADMIN_BUSINESS_REPORT_UPDATE = "/admin/business-report/update/:id";
export const ADMIN_CLAIM_REPORT = "/admin/claim-report";
export const ADMIN_CLAIM_REPORT_DETAIL = "/admin/claim-report/details/:id";
export const ADMIN_CLAIM_REPORT_UPDATE = "/admin/claim-report/update";
export const ADMIN_CLAIM_APPROVE = "/admin/claim-approve";
export const ADMIN_CLAIM_APPROVE_DETAIL = "/admin/claim-approve/details/:id";
export const ADMIN_CLAIM_APPROVE_UPDATE = "/admin/claim-approve/update/:id";

export const ADMIN_CLAIM_CHECK = "/admin/claim-check";

//Bồi thường viên
export const PROVIDER = "/admin/provider/home";

export const PROVIDER_RECOUP = "/admin/provider/recoup";

export const PROVIDER_DOCUMENT = "/admin/provider/document";
export const PROVIDER_BUSINESS = "/admin/provider/business";

export const PROVIDER_CREATE_ACCOUNT = "/admin/provider/account/create";
export const PROVIDER_ACCOUNT = "/admin/provider/account/manage";
export const PROVIDER_PROFILE = "/admin/provider/profile";

// AHAMOVE

export const CLAIM_AHAMOVE = "/claim/ahamove/intransit";
export const COMPENSATION_REPORTR = "/admin/ahamove/compensation-report";

// CLIENT AI
export const CLIENT_AI = "/";

// ADMIN  AI