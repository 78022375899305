import api from '../configs/api';

function login(data) {
  return api.apiForm('POST', '/api/v1/auth/sign-in', data);
};

function getProfile() {
  return api.apiForm('GET', '/api/v1/user/profile');
};

function updateProfile(data) {
  return api.apiForm('POST', '/api/v1/user/edit', data);
};

function updatePWD(data) {
  return api.apiForm('POST', '/api/v1/user/update-password?utm_source=partner-desktop', data);
};

function forgotPWD(data) {
  return api.apiForm('POST', '/api/v1/user/forgot-password?utm_source=desktop', data);
};

export {
  login,
  getProfile,
  updateProfile,
  updatePWD,
  forgotPWD
};
