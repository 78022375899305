import React, { useRef } from 'react';
import {
  Box,
  Button,
  Modal,
  Divider
} from '@mui/material';

const style = {
  padding: "1rem",
  position: 'absolute',
  border: 'none',
  borderRadius: "8px",
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height: "auto",
  background: 'white',
};

export default function ModalImageUpload({ idShow, setShow, imageList, text = '', setText }) {
  const handleClose = () => 
    setTimeout(()=> {
      setShow(false);
    }, 100)
    
  return (
    <Modal
      open={Boolean(idShow)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <h4>Hình ảnh xe</h4>
        <img src={idShow} style={{width: 'auto', height: '70vh'}}/>
        <br/>
        <Button
          onClick={handleClose}
          variant='contained' style={{ background: '#2B65D8', padding: '10px 30px', color: 'white', borderRadius: 11, marginTop: 20 }}>
          Đóng
        </Button>
      </Box>
    </Modal>
  )
}