import { useEffect, useContext, useState } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, TextField, Typography, InputAdornment } from '@mui/material';
import { message } from "antd";
import LoadingButton from '@mui/lab/LoadingButton';

import { LoginLayout } from '../components/layout-login/login-layout';
import iconPhone from '../assets/icons/iconPhone.svg';
import { login, getProfile } from '../../../../apis/authApis'
import { messages } from "../../../../utils/messages";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [showPassWord, setShowPassWord] = useState(false);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .max(255)
        .required(
          'Tên đăng nhập là bắt buộc'),
      password: Yup
        .string()
        .max(255)
        .required(
          'Mật khẩu là bắt buộc')
    }),
    onSubmit: () => {
      handleLogin()
    }
  });
  const token = localStorage.getItem('token') || false
  useEffect(() => {
    if (token) {
      getUserInfo()
    }
  }, [])

  const handleLogin = () => {
    const { email, password } = formik.values
    const params = {
      username: email,
      password
    }
    login(params).then(res => {
      localStorage.setItem('token', res?.data?.result?.token);
      getUserInfo(res?.data?.result);
      window.location.reload();
    })
      .catch(err => {
        console.error('err', err?.response?.data)
        createFail(err?.response?.data)
      })
  }

  const createFail = async (error) => {
    formik.setSubmitting(false)
    message.error({
      content: messages.alert[error?.messageCode] || messages.alert.login_error,
      duration: 5
    })
  }

  const getUserInfo = (data) => {
    getProfile().then(async res => {
      await localStorage.setItem('user', JSON.stringify(res.data.result));
      await localStorage.setItem('permission', JSON.stringify({...res.data.result?.extra_info?.merchant_ai?.permission}))
      history.push('/admin/home');
      formik.setSubmitting(false)
    })
      .catch(err => {
        message.error({
          content: messages.alert[err?.response?.data?.messageCode] || messages.alert.error,
          duration: 5
        })
      })
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexFlow: 'column',
          flexGrow: 1,
          minHeight: '100%',
          background: '#FAFAFA'
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: 'auto' }}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ my: 3 }}>
            <Typography
              fontWeight="fontWeightBold"
              color="textPrimary"
              variant="h4"
            >
              Đăng nhập
            </Typography>
          </Box>
          <TextField
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label="User ID"
            margin="normal"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            focused
          />
          <TextField
            // inputProps={{
            //   autocomplete: 'password',
            //   form: {
            //     autocomplete: 'off',
            //   },
            // }}
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label="Mật khẩu"
            margin="normal"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type={showPassWord ? "text" : "password"}
            value={formik.values.password}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end" onClick={() => setShowPassWord(!showPassWord)}>
                {showPassWord ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>,
            }}
            focused
          />
          <Link
            to="forgot-password"
            color="gray"
            underline="hover"
            sx={{
              cursor: 'pointer',
            }}
            onClick={()=> console.log('q23421423423')}
          >
            <Typography
              textAlign="right"
              color="black"
            >
              Quên mật khẩu?
            </Typography>
          </Link>


          <Box sx={{ py: 2, mt: 5 }}>
            <LoadingButton
              color="gc"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Đăng nhập
            </LoadingButton>
          </Box>
        </form>
        </Container>

        <Box
          textAlign="center"
          mt="auto"
          mx="auto"
          pb={3}
        >
          <Typography sx={{ color: '#1E6BE5' }} mb={1}>
            <img src={iconPhone} />
            Hotline: 1900 2929 87
          </Typography>
          <Typography>
            Một sản phẩm công nghệ của Global Care
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const LoginPage = () => (
  <LoginLayout>
    <Login />
  </LoginLayout>
);

export default LoginPage
