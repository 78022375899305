import { useState } from 'react';
import {
  useLocation,
  Link, useHistory
} from "react-router-dom";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip, ListItemButton, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import * as PATH from '../../../../../configs/routesConfig';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[0]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, setShowModalAccount, setShowModalNotification, ...other } = props;
  let location = useLocation();
  const { goBack } = useHistory();
  const avatar = JSON.parse(localStorage.getItem('user'))?.avatar
  return (
    <>
      <DashboardNavbarRoot
        sx={cssNavbarRoot}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Tooltip title="Back" style={{borderRadius: 5}}>
            <IconButton
              sx={{ ml: 1 }}
              onClick={goBack}
              hidden={showIconBack().status}
            >
              <KeyboardBackspaceIcon /> &nbsp;
              <Box display='flex' flexDirection='column'> 
                <Typography textAlign='left'>{props.titleNavbar}</Typography>
                <Typography color="#000">{props.desNavbar}</Typography>
              </Box >
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Notifications">
            <IconButton
              onClick={setShowModalNotification}
              sx={{ ml: 1 }}>
              <Badge
                badgeContent={props?.badgeContent || 0}
                color="primary"
                variant="standard"
              >
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={setShowModalAccount}
            sx={{ ml: 1 }}>
            <Avatar
              sx={{
                height: 40,
                width: 40,
                ml: 1
              }}
              src={avatar || "/static/images/avatars/avatar_1.png"}
            >
            </Avatar>
          </IconButton>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

const showIconBack = () => {
  const result = { status: false, value: '' }
  const pathName = window.location.pathname

  for (let i = 0; i < items.length; i++) {
    if (pathName.includes(items[i].href)) {
      result['value'] = items[i].title
    }
    if (pathName === items[i].href) {
      result['status'] = true;
      return result
    }
  }
  return result
}

const items = [
  {
    href: PATH.ADMIN,
  },
  {
    href: PATH.ADMIN_SEARCH,
  },
  {
    href: PATH.ADMIN_CONTRACT,
  },
  {
    href: PATH.ADMIN_ACCOUNT_MANAGE,
  },
];

const cssNavbarRoot = {
  left: {
    lg: 280
  },
  width: {
    lg: 'calc(100% - 280px)'
  }
}
