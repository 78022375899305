import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment'
import ButtonGetLink from '../../assets/images/Frame 1000002603.png'
import {
  Link
} from "react-router-dom";
import * as PATH from "../../../../../configs/routesConfig";

const states = [
  { value: 1, label: 'Hiệu lực', },
  { value: 0, label: 'Hết hạn', },
]

export const BusinessReportSearch = ({
  dataFilter = {},
  handleSearch = () => { }
}) => {
  const [fromDate, setFromDate] = useState(dataFilter?.from || moment().add(-31, 'days'));
  const [toDate, setToDate] = useState(dataFilter?.to || moment());
  const [values, setValues] = useState({

  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    setToDate(moment(fromDate).add(31, 'days'))
  }, [fromDate])

  const submit = (e) => {
    e.preventDefault();
  }

  const onSearch = () => {
    let params = ''
    if (values?.code) params += `code=${values.code}&`
    if (values?.licenseNumber) params += `private-key==${values.licenseNumber}&`
    if (values?.status) params += `status=${values.status}&`
    handleSearch(params)
  }

  return (
    <form
      // autoComplete="off"
      // noValidate
      // {...props}
      onSubmit={submit}
    >
      <Grid
        m={1}
        p={1}
        container
        spacing={3}
        style={{ boxShadow: '0px 0px 16px 4px rgba(0, 0, 0, 0.08)', borderRadius: '16px', alignItems: 'center', width: "100%" }}
      >
        <Grid
          item
          md={'auto'}
          xs={12}
          style={{ paddingRight: "15px" }}
        >
          <Link to={PATH.ADMIN_SEARCH_GETLINK}>
            <img src={ButtonGetLink} height="162px" />
          </Link>
        </Grid>
        <Grid
          item
          md={9}
          xs={12}
          style={{ borderLeft: '1px solid #CCCCCC' }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                placeholder="Nhập mã hồ sơ"
                label="Mã hồ sơ"
                name="code"
                onChange={(e) => handleChange(e)}
                value={values.code}
                variant="outlined"
                focused
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                placeholder="Nhập biển số xe"
                label="Biển số xe"
                name="licenseNumber"
                onChange={handleChange}
                value={values.licenseNumber}
                variant="outlined"
                focused
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <FormControl fullWidth focused>
                <InputLabel>Trạng thái</InputLabel>
                <Select
                  select
                  fullWidth
                  placeholder="Chọn trạng thái"
                  name="status"
                  onChange={handleChange}
                  value={values.status || ''}
                  displayEmpty
                  variant="outlined"
                >
                  <MenuItem value="">Chọn tất cả</MenuItem>
                  <MenuItem value="1">Chờ chụp hình</MenuItem>
                  <MenuItem value="2">Hoàn tất</MenuItem>
                  <MenuItem value="3">BKS chưa khớp</MenuItem>
                  <MenuItem value="4">Link hết hạn</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Box display="flex" justifyContent="center">
                <Button
                  type='submit'
                  fullWidth
                  color="gc"
                  variant="contained"
                  sx={{ height: "100%", maxWidth: 250 }}
                  onClick={onSearch}
                >
                  Tra cứu
                </Button>
              </Box>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
