import api from '../configs/api';

function getListClaim(data) {
  return api.apiForm('POST', '/api/v1/order/claim/list', data);
};

function getListYourClaim(data) {
  return api.apiForm('POST', '/api/v1/claim/client/list', data);
};

function getDetailsClaimClient(claimId) {
  return api.apiForm('GET', `/api/v1/claim/client/${claimId}/detail`);
};

function getInfoCarAccident(data) {
  return api.apiForm('POST', '/api/v1/order/claim/detail', data);
};

function getGaraList(data) {
  return api.apiForm('POST', '/api/v1/department/client/garage/list', data);
};

function addOtherGara(data) {
  return api.apiForm('POST', '/api/v1/department/garage', data);
};

function getBankList(data) {
  return api.apiForm('POST', '/api/v1/bank/list', data);
};

function createClaimCarAccident(data) {
  return api.apiForm('POST', '/api/v1/claim/client', data);
};

function getListClaimFromGara(data) {
  return api.apiForm('POST', '/api/v1/claim/department/list', data);
};

function getListClaimFromProvider(data) {
  return api.apiForm('POST', '/api/v1/claim/provider/list', data);
};

function getListClaimFromGlobalCare(data) {
  return api.apiForm('POST', '/api/v1/claim/globalcare/list', data);
};

function getListBusinessFromProvider(data) {
  return api.apiForm('POST', '/api/v1/order/claim/provider/business/list', data);
};

function getListBusinessFromGlobalCare(data) {
  return api.apiForm('GET', `/api/v1/mc-ai/search?${data}`, );
};

function getDatailsClaimCarFromGara(claimId) {
  return api.apiForm('GET', `/api/v1/claim/department/${claimId}/detail`);
};

function getDatailsClaimCarFromProvider(claimId) {
  return api.apiForm('GET', `/api/v1/claim/provider/${claimId}/detail`);
};

function garaSendDataApprove(data) {
  return api.apiForm('PUT', '/api/v1/claim/department/add-elements/request-approve', data);
};

function garaSendDataComplete(data) {
  return api.apiForm('PUT', '/api/v1/claim/department/add-elements/complete', data);
};

function providerApproveClaim(data) {
  return api.apiForm('PUT', '/api/v1/claim/provider/add-elements/approve', data);
};

function providerRejectClaim(data) {
  return api.apiForm('PUT', '/api/v1/claim/provider/add-elements/reject', data);
};

function providerAmendClaim(data) {
  return api.apiForm('PUT', '/api/v1/claim/provider/add-elements/amend', data);
};

function providerGetListEmployee(data) {
  return api.apiForm('POST', '/api/v1/user/provider/employee/list', data);
};

function providerAssignEmployee(data) {
  return api.apiForm('PUT', '/api/v1/claim/provider/assign/user', data);
};

export {
  getListClaim,
  getInfoCarAccident,
  getGaraList,
  addOtherGara,
  getBankList,
  createClaimCarAccident,
  getListClaimFromGara,
  getDatailsClaimCarFromGara,
  garaSendDataApprove,
  getListClaimFromProvider,
  getDatailsClaimCarFromProvider,
  providerApproveClaim,
  providerRejectClaim,
  providerAmendClaim,
  garaSendDataComplete,
  providerGetListEmployee,
  providerAssignEmployee,
  getListClaimFromGlobalCare,
  getListYourClaim,
  getDetailsClaimClient,
  getListBusinessFromProvider,
  getListBusinessFromGlobalCare
};
