import axios from "axios";
import config from "./config";
import { getParamUrlByKey } from "../utils/utils";

function apiForm(method, url, data) {
  try {
    let token =
      getParamUrlByKey('token') || localStorage.getItem("token") || localStorage.getItem("tokenUser") || false;
    switch (method) {
      case "POST":
        return axios({
          method: "POST",
          url: config.SERVER + url,
          data,
          headers: {
            Authorization: `Bearer ${token ? `${token}` : ""}`,
          },
        }).catch((error) => {
          console.log(
            '432432432', error?.response?.status, error?.response
          )
          if (error?.response?.status == 401 && error?.response?.data?.message == "Authorization token is invalid!") {
            if (window.location.pathname.includes('admin') && !window.location.pathname.includes('login') && !window.location.pathname.includes('forgot-password'))
              return window.location.pathname = '/admin/login'
          }
          return error;
        });
      case "GET":
        return axios({
          method: "GET",
          url: config.SERVER + url,
          headers: {
            Authorization: `Bearer ${token ? `${token}` : ""}`,
          },
        }).catch((error) => {
          if (error?.response?.status == 401 && error?.response?.data?.message == "Authorization token is invalid!") {
            if (window.location.pathname.includes('admin') && !window.location.pathname.includes('login') && !window.location.pathname.includes('forgot-password'))
              return window.location.pathname = '/admin/login'
          }
          return error
        });
      case "PUT":
        return axios({
          method: "PUT",
          url: config.SERVER + url,
          data,
          headers: {
            Authorization: `bearer ${token ? `${token}` : ""}`,
          },
        });
      case "DELETE":
        return axios({
          method: "DELETE",
          url: config.SERVER + url,
          headers: {
            Authorization: `Bearer ${token ? `${token}` : ""}`,
          },
        });
      default:
        return;
    }
  } catch (err) {
    return console.log('apiForm', err);
  };
}

function apiForm_formData(method, url, data) {
  try {
    let token =
      getParamUrlByKey('token') || localStorage.getItem("token") || localStorage.getItem("tokenUser") || false;
    return axios({
      method,
      url: config.SERVER + url,
      data,
      headers: {
        Authorization: `Bearer ${token ? `${token}` : ""}`,
      },
    });
  } catch (err) {
    return console.log('apiForm_formData', err);
  };
}

function apiForm1(url, data) {
  try {
    let token =
      localStorage.getItem("token") || localStorage.getItem("tokenUser") || false;
    return axios({
      method: "POST",
      url: config.CORE_SERVER + url,
      data,
      headers: {
        Authorization: `Bearer ${token ? `${token}` : ""}`,
      },
    });
  } catch (err) {
    return console.log('apiForm1', err);
  };
}


async function apicall_g2_formData(method, url, data) {
  let token =
    localStorage.getItem("token") || localStorage.getItem("tokenUser") || false;
  try {
    const res = await axios({
      method: method,
      url: `${config.G2Domain}${url}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token ? `${token}` : ""}`,
      },
    });
    return res;
  } catch (err) {
    return console.log('apicall_g2_formData', err);
  };
};

export default {
  apiForm,
  apiForm_formData,
  apiForm1,
  apicall_g2_formData
};
