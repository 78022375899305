import './App.css';

import AppRouter from './routes';
import StoreProvider from './utils/store/storestate';
import { ModalLoading, ModalListImage } from './ui/modal';
import { RecoilRoot } from 'recoil'
function App() {
  return (
    <StoreProvider>

      <ModalLoading />
      <ModalListImage />
      <RecoilRoot>
        <AppRouter />
      </RecoilRoot>
    </StoreProvider>
  );
}

export default App;
