import api from '../configs/api';

function getListProvider() {
  return api.apiForm('POST', '/api/v1/provider/list');
};

function getListProviderSelected() {
  return api.apiForm('POST', '/api/v1/provider-claim/list');
};

function updateProviderList(data) {
  return api.apiForm('POST', '/api/v1/provider-claim', data);
};

export {
  getListProvider,
  updateProviderList,
  getListProviderSelected
};
