import { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import './account-manager.css'

const options = [
  { label: 'Tra cứu', value: 'search' },
];
const optionsI = [
  { label: 'Lấy link chụp ảnh', value: 'getLink' },
  { label: 'Xuất báo cáo', value: 'export' },
];

export const AccountGara = ({
  handleSelectRole = () => {},
  title = '',
  roleData = [],
  userId = null,
  roleEdit = false
}) => {
  const [checkedList, setCheckedList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedListI, setCheckedListI] = useState(null);
  const [checkAllI, setCheckAllI] = useState(false);
  useEffect(() => {
    if(checkedList && checkAllI ) {
      setCheckAll(true)
    }
    else setCheckAll(false)
  },[checkedList, checkAllI])

  useEffect(() => {
    handleSelectRole({
      fullAccess: checkAll ? 1 : 2,
      search: {
        getLink: checkedListI?.includes('getLink') ? 1 : 2,
        export: checkedListI?.includes('export') ? 1 : 2,
      },
    })
  },[checkAll, checkedListI])

  const onChange = async(list, type) => {
    if(type === 'dashboard') {
      setCheckedList(list.target.checked);
      setCheckAll(list.length === options.length);
    } else {
      setCheckedListI(list);
      setCheckAllI(list.length === optionsI.length);
    }
    // await handleSelectRole(list, type)
  };

  const onCheckAllChange = (checked, type) => {
    let list = []
    let list1 = []
    if(type === 'all') {
      options?.map(item => list.push(item?.value))
      setCheckedList(checked ? true : false);
      setCheckAll(checked);
      optionsI?.map(item => list1.push(item?.value))
      setCheckedListI(checked ? list1 : []);
      setCheckAllI(checked);
    } else {
      optionsI?.map(item => list.push(item?.value))
      setCheckedListI(checked ? list : []);
      setCheckAllI(checked);
    }
    const data = checked ? list : []
    // handleSelectRole(data, type)
  };

  return (
    <Item>
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <Grid
        style={{
          padding: '20px 0 30px 30px',
        }}
        id='manager-global-care'
        container
      >
        <Grid style={{borderRight: '1px solid #cecece'}} pr={1}>
          <Checkbox
            checked={checkAll}
            onChange={(e) => onCheckAllChange(e.target.checked, 'all')}
            disabled={userId && !roleEdit}
          >
          Toàn quyền
          </Checkbox>
          <br/>
          <Checkbox
            checked={checkedList}
            onChange={(e) => onChange(e, 'dashboard')}
            disabled={userId && !roleEdit}
          >
          Dashboard
          </Checkbox>
        </Grid>

        <Grid pl={2}>
          <Checkbox
            onChange={(e) => onCheckAllChange(e.target.checked, 'search')}
            checked={checkAllI}
            disabled={userId && !roleEdit}
          >
          Kiểm tra xử lý hồ sơ
          </Checkbox>
          <Checkbox.Group
            style={{
              display: 'grid',
              padding: '0px 0 15px 44px',
            }}
            options={optionsI}
            value={checkedListI}
            onChange={(e) => onChange(e, 'search')}
            disabled={userId && !roleEdit}
          />
        </Grid>
      </Grid>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));
