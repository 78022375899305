import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import {
    Grid,
    Box,
    TextField,
    FormControlLabel,
    Radio,
    FormControl,
    RadioGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import moment from "moment";
import Swal from 'sweetalert2'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import Loading from '../../commons/modalloading';
import ModalPhotographyGuide from './modal/modalPhotographyGuide';
import ModalCarPrice from './modal/modalCarPrice';
import Banner from '../../assets/images/banner.png';
import iconUpload from '../../assets/icon/icon.svg';

import ModalImageUpload from './modal/modalImageUpload';

import { ReactComponent as IconEconomy } from '../../assets/images/economy.svg';
import { ReactComponent as IconQualtity } from '../../assets/images/qualtity.svg';
import { ReactComponent as IconPremium } from '../../assets/images/premium.svg';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: '15px 0px 0px 0px',
    },
    textField: {
        width: '100%',
        border: '1px solid #C7C7C7',
        borderRadius: 12,
        margin: '15px 0px 0px 0px',
        padding: '5px 20px',
        height: 58,
    },
    textFielddisable: {
        backgroundColor: '#F2F2F2'
    },
    form: {
    },
    buttonbuyins: {
        backgroundColor: '#2B65D8',
        borderRadius: 24,
        color: 'white',
        fontSize: 16,
        textTransform: 'capitalize',
        fontWeight: '500',
        margin: 0
    },
    buttonback: {
        backgroundColor: 'white',
        borderRadius: 24,
        color: '#2B65D8',
        border: '1px solid #2B65D8',
        padding: '10px 15px',
        fontSize: 16,
        textTransform: 'capitalize',
        fontWeight: '500',
        margin: 0
    },
    conbutton: {
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: '10px',
        padding: "20px",
        marginTop: 32,
    }
}));

const arrNumberOfSeatsOrTonnageName = [
    { label: 'Xe dưới 3 tấn', value: 1 },
    { label: 'Xe từ 3 đến 8 tấn', value: 2 },
    { label: 'Xe trên 8 đến 15 tấn', value: 3 },
    { label: 'Xe trên 15 tấn', value: 4 },
]

const Step1 = React.memo((props) => {
    const [vehicleList, setVehicleList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listYearOfManufacture, setListYearOfManufacture] = useState([]);
    const [modalPhotographyGuide, setModalPhotographyGuide] = useState(false);
    const [modalRadio, setModalRadio] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [arrIndexFieldError, setArrIndexFieldError] = useState([])
    const [modalImageUpload, setModalImageUpload] = useState(false);
    const [modalImageUploadMsg, setModalImageUploadMsg] = useState(false);
    const [error, setError] = useState(true)
    const classes = useStyles();
    const refImage = useRef();
    const [search, setSearch] = useState(null);

    const goOn = () => {
        props.orderAction()
    };


    const actionImageUpload = (urlImage) => {
        if (!urlImage) return;
        setModalImageUpload(urlImage);
    }

    const resizeMe = (img) => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        const max_width = 1200;
        const max_height = 1200;
        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        // Compress jpg, 70%
        return canvas.toDataURL("image/jpeg", 0.7);
    }


    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const _resizeVolunmImage = (file, key) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (event) => {
            const blob = new Blob([event.target.result]);
            window.URL = window.URL || window.webkitURL;
            const blobURL = window.URL.createObjectURL(blob);
            const image = new Image();
            image.src = blobURL;
            image.onload = () => {
                const resized = resizeMe(image);
                return (actionUploadImage(DataURIToBlob(resized), key));
            }
        };
    }

    const onClickUploadImage = async (e, key) => {
        if (arrIndexFieldError.length > 0) setArrIndexFieldError([]);
        const _files = e.target.files[0];
        const checkTimestamp = moment().unix() - _files.lastModified / 1000
        if (checkTimestamp > 1) {
            return Swal.fire({
                icon: 'warning',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chụp ảnh từ camera',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
        }
        _resizeVolunmImage(_files, key)
    }

    const actionUploadImage = async (_fileUpload, key) => {
        try {
            setLoading(true);
            const newFile = new File([_fileUpload], `${key}_${Math.random()}.png`)
            let linkImage = await props.uploadImageApi(newFile, `${key}_${Math.random()}`);
            key == "imageInvoice" && props.setImageInvoice([...props?.imageInvoice || [], linkImage])
            key == "imageWindshieldList" && props.setImageWindshieldList([...props?.imageWindshieldList || [], linkImage])
            key == "imageSpeedometerList" && props.setImageSpeedometerList([...props?.imageSpeedometerList || [], linkImage])
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    return (
        <div className={clsx(classes.form)}>
            <div className="back-step">
                <div
                    className="absolute"
                    style={{ width: 30 }}
                >
                </div>
                <div>Chụp hình AI</div>
            </div>
            <Grid container style={{ background: 'white' }}>
                <img src={Banner} width="100%" />

                <Grid style={{ padding: 15, backgroundColor: '#FFF' }} item xs={12} innerRef={refImage}>
                    <Box>
                        <Box
                            style={styleTitle}
                        >
                            Chụp hình ảnh xe hiện tại
                        </Box>
                        <span style={{ color: '#646D69' }}>
                            Đảm bảo hình ảnh chính xác với thời điểm hiện tại (tối thiểu 5 hình, tối đa 10 hình)
                        </span>
                        <Box
                            display='flex'
                            mt={1}
                            // hidden={!props.folderName}
                        >
                            <Box display='flex' style={{ maxWidth: '414px', height: '100px', overflowY: 'scroll' }} >
                                <Box mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box component="label" onClick={(e) => props.setIsShowIframeAI(true)}>
                                        <Box
                                            mt={props?.imageDamagedList?.frontLeft ? 3 : 0}
                                            style={{
                                                border: arrIndexFieldError.indexOf("image") !== -1 ? '1px solid #EF4444' : '1px solid #C4C4C4',
                                                background: '#fff',
                                                borderRadius: '8px',
                                                width: '72px',
                                                height: '72px',
                                                display: 'flex',
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                color: '#2B65D8'
                                            }}
                                        >
                                            <Box><img src={iconUpload} width="33px" /></Box>
                                        </Box>
                                    </Box>
                                </Box>

                                {useMemo(() => (
                                  (Object.keys(props?.imageCapturedList || { 1: '', 2: '', 3: '', 4: '', 5: '' })).map((key, i) => {
                                      let url = ""
                                      if (key == 'other') return;
                                      if ((props?.imageCapturedList || {}).hasOwnProperty(key)) {
                                          url = props?.imageCapturedList[key]
                                      }
                                      if ((props?.imageDamagedDetection || {}).hasOwnProperty(key)) {
                                          url = props?.imageDamagedDetection[key]
                                      }
                                      return (
                                          <>
                                              <Box mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                  <Box textAlign='center'>
                                                      {key == 'frontRight' && <small>Trước - Phải</small>}
                                                      {key == 'frontLeft' && <small>Trước - Trái</small>}
                                                      {key == 'backLeft' && <small>Sau - Trái</small>}
                                                      {key == 'backRight' && <small>Sau - Phải</small>}
                                                      <Box onClick={(e) => actionImageUpload(url)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                          {!+key ? <img src={url} width="100%" height="100%" /> : null}
                                                      </Box>
                                                  </Box>

                                              </Box>
                                          </>
                                      )
                                  })
                              ), [props?.imageCapturedList])
                              }

                                {/* {useMemo(() => (
                                    (Object.keys(props?.imageDamagedDetection || { 1: '', 2: '', 3: '', 4: '', 5: '' })).map((key, i) => {
                                        if (key != 'undefined') {
                                            return (
                                                <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box textAlign='center'>
                                                        {key == 'frontRight' && <small>Trước - Phải</small>}
                                                        {key == 'frontLeft' && <small>Trước - Trái</small>}
                                                        {key == 'backLeft' && <small>Sau - Trái</small>}
                                                        {key == 'backRight' && <small>Sau - Phải</small>}
                                                        <Box onClick={(e) => actionImageUpload(props.imageDamagedDetection[key])} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                            {!+key ? <img src={props.imageDamagedDetection[key]} width="100%" height="100%" /> : null}
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            )
                                        }
                                    })
                                ), [props?.imageDamagedDetection])
                                } */}

                                <Box hidden={!props?.imageCapturedList?.other} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box>
                                        <small>Tem kiểm định</small>
                                        <Box onClick={(e) => actionImageUpload(props?.imageCapturedList?.other)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                            <img src={props?.imageCapturedList?.other} width="100%" height="100%" />
                                        </Box>
                                    </Box>
                                </Box>

                                {useMemo(() => (
                                    (props?.imageDamagedList?.frontLeft || [1, 2, 3, 4, 5]).map((v, i) => {
                                        // if (v?.url) {
                                        return (
                                            <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box>
                                                    {v?.url ? <small>Trước - Trái</small> : null}
                                                    <Box onClick={(e) => actionImageUpload(v?.url)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                        {v?.url ? <img src={v?.url} width="100%" height="100%" /> : null}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                        // }
                                    })
                                ), [props?.imageDamagedList?.frontLeft])
                                }

                                {useMemo(() => (
                                    (props?.imageDamagedList?.frontRight || []).map((v, i) => {
                                        if (v?.url) {
                                            return (
                                                <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box>
                                                        {v?.url ? <small>Trước - Phải</small> : null}
                                                        <Box onClick={(e) => actionImageUpload(v?.url)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                            <img src={v?.url} width="100%" height="100%" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })
                                ), [props?.imageDamagedList?.frontRight])
                                }
                                {useMemo(() => (
                                    (props?.imageDamagedList?.backLeft || []).map((v, i) => {
                                        if (v?.url) {
                                            return (
                                                <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box>
                                                        {v?.url ? <small>Sau - Trái</small> : null}
                                                        <Box onClick={(e) => actionImageUpload(v?.url)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                            <img src={v?.url} width="100%" height="100%" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })
                                ), [props?.imageDamagedList?.backLeft])
                                }
                                {useMemo(() => (
                                    (props?.imageDamagedList?.backRight || []).map((v, i) => {
                                        if (v?.url) {
                                            return (
                                                <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box>
                                                        {v?.url ? <small>Sau - Phải</small> : null}
                                                        <Box onClick={(e) => actionImageUpload(v?.url)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                            <img src={v?.url} width="100%" height="100%" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })
                                ), [props?.imageDamagedList?.backRight])
                                }
                                {useMemo(() => (
                                    (Object.keys(props?.imageCustomList)).map((key, i) => (
                                        props?.imageCustomList[key] && props?.imageCustomList[key]?.length > 0 ?
                                            (props?.imageCustomList[key] || []).map((v, i) => (
                                                <Box key={i} mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box>
                                                        {v ? <small>Hình bổ sung</small> : null}
                                                        <Box onClick={(e) => actionImageUpload(v)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                                                            <img src={v} width="100%" height="100%" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                            ) : <></>
                                    ))
                                ), [props?.imageCustomList])
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ height: 120 }}></Box>
            <Box
                style={{
                    position: 'fixed',
                    bottom: 0,
                    maxWidth: '414px',
                    width: '100vw',
                    background: "white",
                    padding: 8,
                    borderRadius: "16px 16px 0 0",
                    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    padding: 16,
                }}
            >
                <button
                    disabled={props.loading || props.error}
                    type="button"
                    style={{
                        color: props.error ? "#C4CFC9" : "white",
                        border: "none",
                        background: props.error ? "#EBF0ED" : "#009B4B",
                        borderRadius: "6px",
                        fontSize: 20,
                        fontWeight: 600,
                        width: "100%",
                        lineHeight: 2,
                    }}
                    onClick={goOn}
                >
                    Gửi
                </button>
            </Box>
            {
                loading && (
                    <Loading
                        content={'Đang xử lý ảnh ...'}
                    />
                )
            }
            <ModalPhotographyGuide show={modalPhotographyGuide} setShow={setModalPhotographyGuide} />
            <ModalCarPrice show={modalRadio == 'vehiclePrice'} setShow={setModalRadio} />
            {modalImageUpload && (
                <ModalImageUpload
                    idShow={modalImageUpload}
                    setShow={setModalImageUpload}
                    imageList={props.imageList}
                    text={modalImageUploadMsg}
                    setText={setModalImageUploadMsg} />
            )
            }

        </div>
    );
}, (prevProps, nextProps) => validateRerender(prevProps, nextProps));

const WidgetInsuranceFees = React.memo(({
    error,
    setError,
    vehicleInfo,
    arrCheck,
    feesDetailInfo,
    setFeesDetail,
    setVehicleInfo,
    valueCheckBox,
    apiCommission,
    imageDamagedDetection,
    imageWindshieldList,
    imageSpeedometerList,
    imageInvoice
}) => {
    const [feesEconomy, setFeesEconomy] = useState(0);
    const [feesQuality, setFeesQuality] = useState(0);
    const [feesPremium, setFeesPremium] = useState(0);
    useEffect(() => {
        loadPrice();
    }, [
        vehicleInfo.typePackage,
        vehicleInfo.carId,
        vehicleInfo.yearOfManufacture,
        vehicleInfo.numberOfSeatsOnCavet,
        vehicleInfo.typeId
    ])

    useEffect(() => {
        let arrIndexFieldError = []
        for (let i = 0; i < arrCheck.length; i++) {
            if (arrCheck[i].id > 9) continue;
            if (!arrCheck[i]?.hidden) {
                if (!vehicleInfo[arrCheck[i]?.key] || vehicleInfo[arrCheck[i]?.key] == '') {
                    arrIndexFieldError.push(arrCheck[i]?.id)
                }
            }
        }
        if (valueCheckBox['27'].bks == 1) { // co bien so
            if (!imageDamagedDetection?.frontLeft || !imageDamagedDetection?.backLeft || !imageDamagedDetection?.frontRight || !imageDamagedDetection?.backRight) {
                arrIndexFieldError.push('imageVCX1')
            }
            // if (imageWindshieldList?.length == 0 || imageSpeedometerList?.length == 0) {
            //     arrIndexFieldError.push('imageVCX2')
            // }
        }
        // if (valueCheckBox['27'].bks == 2) { // khong bien so
        //     if (imageInvoice?.length == 0) {
        //         arrIndexFieldError.push('imageVCX3')
        //     }
        // }

        if (arrIndexFieldError.length === 0) setError(false)
        else setError(true)
    })

    useEffect(() => {
        if (vehicleInfo?.typePackage == 'economy') {
            return setFeesDetail({ ...feesEconomy })
        }
        if (vehicleInfo?.typePackage == 'quality') {
            return setFeesDetail({ ...feesQuality })
        }
        if (vehicleInfo?.typePackage == 'premium') {
            return setFeesDetail({ ...feesPremium })
        }
    }, [feesEconomy, feesQuality, feesPremium]);

    const loadPrice = async () => {
        const arrIndexFieldError = []

        for (let i = 0; i < arrCheck.length; i++) {
            if (arrCheck[i].id > 9) continue;
            if (!arrCheck[i]?.hidden) {
                if (!vehicleInfo[arrCheck[i]?.key] || vehicleInfo[arrCheck[i]?.key] == '') {
                    arrIndexFieldError.push(arrCheck[i]?.id)
                }
            }
        }

        if (arrIndexFieldError.length === 0) {
            if (vehicleInfo?.typePackage && vehicleInfo?.typePackage != '') apiCommission(valueCheckBox);
            let getFeesEconomy = await action({ additional_02: 1, additional_04: 2, additional_05: 2, additional_06: 1 });
            let getFeesQuality = await action({ additional_02: 1, additional_04: 2, additional_05: 1, additional_06: 1 });
            let getFeesPrimary = await action({ additional_02: 1, additional_04: 1, additional_05: 1, additional_06: 1 });
            setFeesEconomy(getFeesEconomy || 0)
            setFeesQuality(getFeesQuality || 0)
            setFeesPremium(getFeesPrimary || 0)
        }
    }

    const choosePackage = (type) => {
        if (type == 'economy') {
            setFeesDetail({ ...feesEconomy })
            return setVehicleInfo((vehicleInfo) => { return { ...vehicleInfo, typePackage: 'economy', additional_02: 1, additional_04: 2, additional_05: 2, additional_06: 1 } });
        }
        if (type == 'quality') {
            setFeesDetail({ ...feesQuality })
            return setVehicleInfo((vehicleInfo) => { return { ...vehicleInfo, typePackage: 'quality', additional_02: 1, additional_04: 2, additional_05: 1, additional_06: 1 } });
        }
        if (type == 'premium') {
            setFeesDetail({ ...feesPremium })
            return setVehicleInfo((vehicleInfo) => { return { ...vehicleInfo, typePackage: 'premium', additional_02: 1, additional_04: 1, additional_05: 1, additional_06: 1 } });
        }
    }

    const action = async ({
        additional_02 = false,
        additional_04 = false,
        additional_05 = false,
        additional_06 = false
    }) => {
        const data = {
            productCode: 'bbot_vcx',
            providerId: 1,
            vehicleInfo: {
                additional: {
                    additional_02: additional_02 || vehicleInfo.additional_02,
                    additional_04: additional_04 || vehicleInfo.additional_04,
                    additional_05: additional_05 || vehicleInfo.additional_05,
                    additional_06: additional_06 || vehicleInfo.additional_06,
                },
                carId: vehicleInfo.carId,
                yearOfManufacture: vehicleInfo.yearOfManufacture,
                typeId: vehicleInfo.typeId,
                carOccupantAccidentInsurance: {
                    buy: vehicleInfo?.carOccupantAccidentInsurance,
                    numberOfSeats: vehicleInfo.typeId == 1 ? vehicleInfo.numberOfSeatsOnCavet : vehicleInfo.numberOfSeatsLevel,
                    ignoreFees: valueCheckBox['4']['checked'] && valueCheckBox['27']['checked'] ? 1 : 2,
                    level: vehicleInfo?.level
                },
                numberOfSeatsOnCavet: vehicleInfo.numberOfSeatsOnCavet,
                numberOfSeatsOrTonnageName:
                    vehicleInfo.typeId == 3
                        ?
                        arrNumberOfSeatsOrTonnageName.filter((v) => v.value == vehicleInfo?.numberOfSeatsOrTonnage)[0].label
                        :
                        vehicleInfo.numberOfSeatsOnCavet < 6
                            ? 'dưới 6 chỗ ngồi'
                            : vehicleInfo?.numberOfSeatsOnCavet >= 6 && vehicleInfo?.typeId == 1 // KKD
                                ? 'từ 6 đến 11 chỗ ngồi'
                                : vehicleInfo?.numberOfSeatsOnCavet >= 6 && vehicleInfo?.typeId == 2 // KD
                                    ? '7 chỗ ngồi'
                                    : '',
                numberOfSeatsOrTonnage: vehicleInfo.typeId == 3 ? vehicleInfo?.numberOfSeatsOrTonnage :
                    vehicleInfo?.numberOfSeatsOnCavet < 6
                        ? 1
                        : vehicleInfo?.numberOfSeatsOnCavet >= 6 && vehicleInfo?.typeId == 2 // KD
                            ? 3
                            : vehicleInfo?.numberOfSeatsOnCavet >= 6 && vehicleInfo?.typeId == 1 // KKD
                                ? 2
                                : null
            },
            insuredInfo: {
                yearsOfInsurance: valueCheckBox['27']['duration'],
            },
        }
        const body = { data: Buffer.from(JSON.stringify(data)).toString('base64') }
    }

    return (
        <>
            <Box
                onClick={() => choosePackage('economy')}
                style={{
                    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.08)',
                    borderRadius: '5px',
                    background: vehicleInfo.typePackage == 'economy' ? '#009B4B' : "white",
                    marginLeft: 20,
                    padding: '10px 10px'
                }}>
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Box style={{ marginLeft: -30, flex: 1 }}>
                        <Box style={{ height: "1rem" }}>
                            <IconEconomy fill={vehicleInfo.typePackage == 'economy' ? '#ED6D00' : '#3E9C49'} />
                        </Box>
                    </Box>
                    <Box
                        style={{
                            color: vehicleInfo.typePackage == 'economy' ? '#FFF' : '#FB7800',
                            fontWeight: 600,
                            textAlign: 'right'
                        }}>
                        {/* {FormatMoney(feesEconomy?.fees || 0)} VNĐ */}
                    </Box>
                </Box>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'economy' ? '#009B4B' : "white" }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'economy' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'economy' ? 'white' : "#3E9C49" }}>Quyền Lợi Chính:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'economy' ? 'white' : "#646D69" }}>
                            <li>Tổn thất toàn bộ và mất cắp toàn bộ</li>
                            <li>Tổn thất bộ phận</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'economy' ? '#009B4B' : "white" }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'economy' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'economy' ? 'white' : "#3E9C49" }}>Quyền Lợi Bổ sung:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'economy' ? 'white' : "#646D69" }}>
                                <li>Bảo hiểm Khấu hao thay thế mới</li>
                                <li>Bảo hiểm Thuỷ Kích</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <br />
            <Box
                onClick={() => choosePackage('quality')}
                style={{
                    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.08)',
                    borderRadius: '5px',
                    background: vehicleInfo.typePackage == 'quality' ? '#009B4B' : "white",
                    marginLeft: 20,
                    padding: '10px 10px'
                }}>
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Box style={{ marginLeft: -30, flex: 1 }}>
                        <Box style={{ height: 32 }}>
                            <IconQualtity fill={vehicleInfo.typePackage == 'quality' ? '#ED6D00' : '#3E9C49'} />
                        </Box>
                    </Box>

                    {/* <Box style={{ color: vehicleInfo.typePackage == 'quality' ? '#FFF' : '#FB7800', fontWeight: 600, textAlign: 'right' }}>{FormatMoney(feesQuality?.fees || 0)} VNĐ</Box> */}
                </Box>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'quality' ? '#009B4B' : "white", }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'quality' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'quality' ? 'white' : "#3E9C49" }}>Quyền Lợi Chính:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'quality' ? 'white' : "#646D69" }}>
                            <li>Tổn thất toàn bộ và mất cắp toàn bộ</li>
                            <li>Tổn thất bộ phận</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'quality' ? '#009B4B' : "white", }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'quality' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'quality' ? 'white' : "#3E9C49" }}>Quyền Lợi Bổ sung:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'quality' ? 'white' : "#646D69" }}>
                                <li>Bảo hiểm Khấu hao thay thế mới</li>
                                <li>Bảo hiểm Thuỷ Kích</li>
                                <li>Bảo hiểm Sửa chữa chính hãng</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <br />
            <Box
                onClick={() => choosePackage('premium')}
                style={{
                    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.08)',
                    borderRadius: '5px',
                    background: vehicleInfo.typePackage == 'premium' ? '#009B4B' : "white",
                    marginLeft: 20,
                    padding: '10px 10px'
                }}>
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Box style={{ marginLeft: -30, flex: 1 }}>
                        <Box style={{ height: 32 }}>
                            <IconPremium fill={vehicleInfo.typePackage == 'premium' ? '#ED6D00' : '#3E9C49'} />
                        </Box>
                    </Box>
                    {/* <Box style={{ color: vehicleInfo.typePackage == 'premium' ? '#FFF' : '#FB7800', fontWeight: 600, textAlign: 'right' }}>{FormatMoney(feesPremium?.fees || 0)} VNĐ</Box> */}
                </Box>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'premium' ? '#009B4B' : "white", }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'premium' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'premium' ? 'white' : "#3E9C49" }}>Quyền Lợi Chính:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'premium' ? 'white' : "#646D69" }}>
                            <li>Tổn thất toàn bộ và mất cắp toàn bộ</li>
                            <li>Tổn thất bộ phận</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion onClick={(e) => e.stopPropagation()} style={{ boxShadow: 'none', background: vehicleInfo.typePackage == 'premium' ? '#009B4B' : "white", }}>
                    <AccordionSummary
                        expandIcon={<img src={vehicleInfo.typePackage == 'premium' ? '/core_tnds+vcx_oto/arrow_down_white.svg' : '/core_tnds+vcx_oto/arrow_down_green.svg'} />}
                    >
                        <Typography><u style={{ color: vehicleInfo.typePackage == 'premium' ? 'white' : "#3E9C49" }}>Quyền Lợi Bổ sung:</u></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ul style={{ margin: '0!important', color: vehicleInfo.typePackage == 'premium' ? 'white' : "#646D69" }}>
                                <li>Bảo hiểm Khấu hao thay thế mới</li>
                                <li>Bảo hiểm Thuỷ Kích</li>
                                <li>Bảo hiểm Sửa chữa chính hãng</li>
                                <li>Bảo hiểm Mất cắp bộ phận (giới hạn 2 lần/năm)</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
}, (prevProps, nextProps) => validateRerender(prevProps, nextProps));


const validateRerender = (prevProps, nextProps) => {

    if (nextProps?.vehicleInfo?.typeId == 2) {
        const checkYears = new Date().getFullYear() - nextProps.vehicleInfo.yearOfManufacture
        if (nextProps?.vehicleInfo?.numberOfSeatsOnCavet < 9) {
            if (checkYears >= 10) {
                nextProps.setVehicleInfo({ ...nextProps.vehicleInfo, yearOfManufacture: undefined })
                // Swal.fire({
                //     icon: 'warning',
                //     title: 'Thông báo lỗi',
                //     text: 'Xe chỉ hỗ trợ tối đa 10 năm',
                //     showClass: {
                //         popup: 'animate__animated animate__fadeInDown'
                //     },
                //     hideClass: {
                //         popup: 'animate__animated animate__fadeOutUp'
                //     }
                // })
                return true;
            }
        }
    }
    if (nextProps.vehicleInfo.yearOfManufacture && nextProps?.vehicleInfo?.additional_05 == 1) {
        const checkYears = new Date().getFullYear() - nextProps.vehicleInfo.yearOfManufacture
        if (checkYears > 5) {
            nextProps.setVehicleInfo({ ...nextProps.vehicleInfo, typePackage: '', additional_02: undefined, additional_04: undefined, additional_05: undefined, additional_06: undefined })
            // Swal.fire({
            //     icon: 'warning',
            //     title: 'Thông báo lỗi',
            //     text: 'Quyền lợi bổ sung này chỉ áp dụng với xe có thời hạn sử dụng dưới 5 năm',
            //     showClass: {
            //         popup: 'animate__animated animate__fadeInDown'
            //     },
            //     hideClass: {
            //         popup: 'animate__animated animate__fadeOutUp'
            //     }
            // })
            return true;
        }
    }
    if (nextProps?.vehicleInfo?.numberOfSeatsOnCavet > 11) {
        nextProps.setVehicleInfo({ ...nextProps.vehicleInfo, numberOfSeatsOnCavet: "" })
        // Swal.fire({
        //     icon: 'warning',
        //     title: 'Thông báo lỗi',
        //     text: 'Chỉ áp dụng xe từ 11 chỗ trở lại.\n Cần hỗ trợ liên hệ hotline 1900292987.',
        //     showClass: {
        //         popup: 'animate__animated animate__fadeInDown'
        //     },
        //     hideClass: {
        //         popup: 'animate__animated animate__fadeOutUp'
        //     }
        // })
        return true;
    }
    if (nextProps?.vehicleInfo?.numberOfSeatsLevel && nextProps?.vehicleInfo?.numberOfSeatsLevel != "") {
        if (!/^\d+$/.test(nextProps?.vehicleInfo?.numberOfSeatsLevel) || nextProps?.vehicleInfo?.numberOfSeatsLevel < 1) {
            nextProps.setVehicleInfo({ ...nextProps.vehicleInfo, numberOfSeatsLevel: "" })
            // return Swal.fire({
            //     icon: 'warning',
            //     title: 'Thông báo lỗi',
            //     text: 'Số ghế không phù hợp. Vui lòng chọn/nhập lại',
            //     showClass: {
            //         popup: 'animate__animated animate__fadeInDown'
            //     },
            //     hideClass: {
            //         popup: 'animate__animated animate__fadeOutUp'
            //     }
            // })
        }
    }

    return false;
}

const ImageInvoice = memo((props) => (
    (props?.imageInvoice?.length > 0 ? props?.imageInvoice : [1, 2]).map((v, i) => {
        return (
            <Box mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box onClick={(e) => props?.actionImageUpload(v)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                    {!+v ? <img src={v} width="100%" height="100%" /> : null}
                    <Box onClick={(e) => props?.spliceImageList(e, i - 1)} hidden={+v} style={{ position: 'absolute', top: -10, right: -10 }}>
                        <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.36328" cy="8.16211" r="8" fill="white" />
                            <path d="M4.36328 4.16211L12.3633 12.1621" stroke="#BF272D" />
                            <path d="M4.36328 12.1621L12.3633 4.16211" stroke="#BF272D" />
                        </svg>
                    </Box>
                </Box>
            </Box>
        )
    })));

const ImageWindshieldList = memo((props) => (
    (props?.imageWindshieldList?.length > 0 ? props?.imageWindshieldList : [1, 2]).map((v, i) => {
        return (
            <Box mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box onClick={(e) => props?.actionImageUpload(v)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                    {!+v ? <img src={v} width="100%" height="100%" /> : null}
                    <Box onClick={(e) => props?.spliceWindshieldList(e, i - 1)} hidden={+v} style={{ position: 'absolute', top: -10, right: -10 }}>
                        <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.36328" cy="8.16211" r="8" fill="white" />
                            <path d="M4.36328 4.16211L12.3633 12.1621" stroke="#BF272D" />
                            <path d="M4.36328 12.1621L12.3633 4.16211" stroke="#BF272D" />
                        </svg>
                    </Box>
                </Box>
            </Box>
        )
    })));

const ImageSpeedometerList = memo((props) => (
    (props?.imageSpeedometerList?.length > 0 ? props?.imageSpeedometerList : [1, 2]).map((v, i) => {
        return (
            <Box mx={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box onClick={(e) => props?.actionImageUpload(v)} style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                    {!+v ? <img src={v} width="100%" height="100%" /> : null}
                    <Box onClick={(e) => props?.spliceSpeedometerList(e, i - 1)} hidden={+v} style={{ position: 'absolute', top: -10, right: -10 }}>
                        <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.36328" cy="8.16211" r="8" fill="white" />
                            <path d="M4.36328 4.16211L12.3633 12.1621" stroke="#BF272D" />
                            <path d="M4.36328 12.1621L12.3633 4.16211" stroke="#BF272D" />
                        </svg>
                    </Box>
                </Box>
            </Box>
        )
    })));

const styleTitle = {
    textTransform: 'uppercase',
    color: "#515855",
    fontSize: '0.9rem',
    fontWeight: 'bold',
}

export default Step1;