import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import { getListProviderClaim } from '../../../../../apis/adminManagerAccountApis';

import './account-manager.css'

export const AccountInsuranceSystem = ({
  handleSelectRole = () => {},
  title = '',
  providerId = null,
  roleEdit = false
}) => {
  const [checked, setChecked] = useState([]);
  const [listProvider, setListProvider] = useState([]);

  useEffect(() => {
    if(providerId) {
      const newData = []
      newData[providerId] = providerId
      setChecked(newData)
      handleSelectRole('', providerId)
    }
  }, [providerId])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    getListProviderClaim().then(async res => {
      const data = res?.data?.result?.data
      setListProvider(data)
    })
    .catch(err => {
      console.error('err', err)
    })
  }

  const onChange = value => () => {
    const newData = []
    newData[value] = value
    setChecked(newData)
    handleSelectRole('', value)
  };

  return (
    <Item>
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <Grid
        style={{
          padding: '20px 0 10px 30px',
          borderBottom: '1px solid #E1E1E1',
          marginLeft: 0,
        }}
        spacing={3}
        xs={12}
        id='manager-global-care'
      >
        {listProvider?.map((item, index) => {
          return(
            <Grid>
              <Checkbox
                onChange={onChange(item.provider_id)}
                checked={checked[item.provider_id]}
                disabled={providerId && !roleEdit}
              >
              {item.provider_title}{item.provider_code ? ` (${item.provider_code})` : ''}
              </Checkbox>
            </Grid>
          )
        })}
      </Grid>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));
