import moment from 'moment'
import {
  Link, useHistory
} from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Badge
} from '@mui/material';
import { Table } from "antd";

import { SeverityPill } from '../severity-pill';
import iconDownload from '../../assets/icons/iconDownload.svg';
import * as PATH from '../../../../../configs/routesConfig';
import { downloadFile } from '../../../../../utils/downloadFile';
import './account-manager.css'
import { Excel } from "antd-table-saveas-excel";

export const AccountTable = ({
  data = [],
  isLoading = false,
  roleAccount = null,
  dataFilter = null,
}) => {
  const user = JSON.parse(localStorage.getItem('user')) || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && user?.extra_info?.userType) || 0
  const history = useHistory()
  const columns = [
    {
      title: "#",
      key: '#',
      render: (value, record, index) => {
        return index + 1
      },
      width: '5%',
    },
    {
      title: "Họ tên",
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
      render: (value) => {
        return (
          <div style={styleValueTable}>
            {value}
          </div>
        )
      },
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      render: (value) => {
        return (
          <div style={styleValueTable}>
            {value}
          </div>
        )
      },
    },
    {
      title: "User đăng nhập",
      dataIndex: 'username',
      key: 'username',
      width: '15%',
      render: (value) => {
        return (
          <div style={styleValueTable}>
            {value}
          </div>
        )
      },
    },
    {
      title: "Tình trạng",
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (value) => {
        return (
          <SeverityPill
            color={contants_status[value].value}
          >
            {contants_status[value].label}
          </SeverityPill>
        )
      },
    },
    {
      title: "Chi tiết",
      dataIndex: 'username',
      width: '10%',
      render: (value) => {
        return (
          <a
            onClick={() =>
              history.push(`/admin/manage/account/update/${value}`,
                {
                  // userId: value,
                  roleEdit: roleAccount?.view_and_edit === 1
                })
            }
            style={{ maxWidth: '90%' }}
          >
            <u>Chi tiết TK</u>
          </a>
        )
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: 'created_date',
      key: 'created_date',
      width: '10%',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY')
      },
    },
  ];

  const columnsExcel = [
    {
      title: "#ID",
      key: 'id',
      dataIndex: "id",
    },
    {
      title: "Họ tên",
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: "Email",
      dataIndex: 'email',
    },
    {
      title: "User đăng nhập",
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: "Tình trạng",
      dataIndex: 'status',
      key: 'status',
      excelRender: (value, row, index) => {
          return {
            children: contants_status[value].label,
        }
      }
    },
    {
      title: "Ngày tạo",
      dataIndex: 'created_date',
      key: 'created_date',
      excelRender: (value, row, index) => {
          return {
            children: moment(value).format('DD/MM/YYYY'),
          };
      }
    },
  ];

  const exportData = () => {
    const excel = new Excel();
    excel
      .addSheet("QLTK")
      .addColumns(columnsExcel)
      .addDataSource(data, {
        str2Percent: true
      })
      .saveAs("BaoCaoQuanLyTaiKhoan.xlsx");
  }

  return (
    <Box sx={{ minWidth: 800 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} hidden={!dataFilter}>
        <Typography
          variant="h5"
          my={4}
        >
          Kết quả tra cứu
          &nbsp;&nbsp;
          <span>
            <Badge color="success" variant='dot'></Badge> &nbsp;
            <small style={{ fontSize: 15, color: 'grey', fontWeight: '400' }}>{data?.length || 0} kết quả</small>
          </span>
        </Typography>
        <Button
          hidden={user?.extra_info?.merchant_ai?.permission?.portalAI?.account?.export != 1}
          color="primary"
          onClick={exportData}
        >
          <img alt='iconDownload' src={iconDownload} /> &nbsp; Xuất báo cáo
        </Button>
      </Box>
      <Table
        columns={columns}
        rowClassName={(record, index) => index % 2 !== 0 ? 'table-row-light' : 'table-row-dark'}
        dataSource={data}
        loading={isLoading}
        size='small'
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
      />
    </Box>
  )
};

const contants_status = [
  { value: 'lock', label: 'Không hoạt động', },
  { value: 'active', label: 'Hoạt động', },
]

const styleValueTable = {
  color: '#000000',
  maxWidth: '90%',
  fontSize: 15,
  wordBreak: 'break-word',
  wordBreak: 'break-all'
}
