import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import api from "../../../../../configs/api";

import ButtonGetLink from '../../assets/images/Frame 1000002560.png'
import bgSearch from '../../assets/images/bgSearch.png'
import btnCopy from '../../assets/images/Frame 1000002622.svg'
import iconCopy from '../../assets/images/akar-icons_link-chain.svg'
import ModalImageUpload from './modalImageUpload';

import moment from 'moment';

export const Detail = (props) => {
  const [value, setValue] = useState('');
  const [modalImageUpload, setModalImageUpload] = useState(false);
  const [link, setLink] = useState('');

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value
    });
  };

  const actionImageUpload = (urlImage) => {
    if (!urlImage) return;
    setModalImageUpload(urlImage);
  }

  const submit = async (e) => {
    e.preventDefault();
    const response = await api.apiForm('GET', `/api/v1/mc-ai/get-link?private-key=${value?.code}`)
    if (response.data.statusCode !== 400) {
      setLink(response.data.result.link)
    }
  }

  const copy = (link) => {
    window.navigator.clipboard.writeText(link)
    return alert('Sao chép thành công')
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" px={2}>
        <Box ><b>Mã hồ sơ: <span style={{ color: '#383D3B' }}>{props?.details?.code}</span></b></Box>
        <Box ><b>Trạng thái: &nbsp;
          {props?.details?.status == 2 && <span style={{ color: 'green' }}>Hoàn tất</span>}
          {props?.details?.status == 3 && <span style={{ color: 'red' }}>BKS chưa khớp</span>}
          {props?.details?.status == 4 && <span style={{ color: 'grey' }}>Link hết hạn</span>}
          {props?.details?.status == 1 && <span style={{ color: '#007DC5' }}>
            Chờ chụp hình
          </span>
          }
        </b></Box>
      </Box>
      <Box
        m={2}
        style={{
          height: '210px',
          background: `url(${bgSearch})`,
          backgroundSize: '100% auto',
          borderRadius: 16,
        }}
      >
      </Box>
      <Box height={10}></Box>
      <Box
        m={2}
        style={{
          boxShadow: '0px 0px 16px 4px rgba(0, 0, 0, 0.08)',
          borderRadius: '16px',
        }}>
        <Grid
          container
          spacing={3}
          p={2}

        >
          <Grid
            item
            md={4}
            xs={12}
            style={{ borderRight: '1px solid #cecece' }}
          >
            <b>Thông tin xe</b>
            <Box padding={2}>
              <Box display="flex" justifyContent="space-between" p={1}>
                <Box>Mã hồ sơ</Box>
                <Box>{props?.details?.code}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between" p={1}>
                <Box>Trạng thái</Box>
                <Box
                  color={props?.details?.status == 2 ? "green" : props?.details?.status == 3 ? 'red' : props?.details?.status == 4 ? 'grey' : 'black'}
                >

                  {props?.details?.status == 2 ? 'Hoàn tất' : props?.details?.status == 3 ? 'BKS chưa khớp' :  props?.details?.status == 4 ? 'Link hết hạn11': ''}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" p={1} >
                <Box>Biển số xe nhập vào</Box>
                <Box textTransform="uppercase">{props?.details?.private_key}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between" p={1}>
                <Box>Biển số xe ở hình chụp</Box>
                {/* <Box>{
                  props?.details?.extra_info?.license
                    ? props?.details?.extra_info?.license?.slice(0, 3) + "-" + props?.details?.extra_info?.license?.slice(3)
                    : null
                }</Box> */}
              </Box>
              <Box pl={1} ml={4}>
                <ul>
                  {(props?.details?.extra_info?.licenses || []).map((v, i) => {
                    const [key] = Object.keys(v)
                    if (key == 'other') return;
                    return (
                      <li>
                        <Box display="flex" justifyContent="space-between" p={1}>
                          {key == 'frontRight' && <Box>Trước - Phải</Box>}
                          {key == 'frontLeft' && <Box>Trước - Trái</Box>}
                          {key == 'backLeft' && <Box>Sau - Trái</Box>}
                          {key == 'backRight' && <Box>Sau - Phải</Box>}
                          <Box
                            textTransform="uppercase"
                            color={props?.details?.extra_info?.licenses[i][key] == props?.details?.private_key.toLowerCase() ? '#39B44A' : '#FF0000'}
                          >
                            {props?.details?.extra_info?.licenses[i][key]}
                          </Box>
                        </Box>
                      </li>
                    )
                  })
                  }
                </ul>
              </Box>
              <Box p={1} hidden={props?.details?.status == 2 || props?.details?.status == 3 || props?.details?.status == 4}>
                Link chụp ảnh
                <Box
                  hidden={!Boolean(props?.details?.link)}
                  onClick={() => copy(props?.details?.link)}
                  style={{ background: 'white', display: 'flex', alignItems: 'center', height: 45, borderRadius: 8 }}>
                  &nbsp;&nbsp;
                  <img src={iconCopy} height="36px" />
                  &nbsp;&nbsp;
                  <Box
                    width="calc(100% - 70px)"
                    component="a"
                    href={props?.details?.link}
                    target="_blank"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {props?.details?.link}
                  </Box>
                  <img src={btnCopy} height="100%" />
                </Box>
              </Box>
              <Box p={1} hidden={props?.details?.status != 4}>
                Link chụp ảnh
                <Box
                  hidden={!Boolean(props?.details?.link)}
                  // onClick={() => copy(props?.details?.link)}
                  style={{ background: 'white', display: 'flex', alignItems: 'center', height: 45, borderRadius: 8 }}>
                  &nbsp;&nbsp;
                  <img src={iconCopy} height="20px" style={{filter: 'grayscale(100%)'}}/>
                  &nbsp;&nbsp;
                  <Box
                    width="calc(100% - 70px)"
                    // component="a"
                    // href={props?.details?.link}
                    target="_blank"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',color: 'grey' }}
                  >
                    {props?.details?.link}
                  </Box>
                  {/* <img src={btnCopy} height="100%" /> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          >
            <Box display="flex" justifyContent="space-between" color="#1A437E">
              <Box>
                <b>Hình ảnh xe</b>
              </Box>
              <Box>
                <i>Ngày {moment(props.details.created_date).format('DD/MM/YYYY')}</i>
              </Box>
            </Box>
            <Box mx={1} style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
              {(Object.keys(props.details?.extra_info?.captured || {})).map((key, i) => {
                let license = ''
                let url = ""
                props?.details?.extra_info?.licenses.filter((v) => {
                  const [value] = Object.keys(v)
                  if (value == key) {
                    license = v[value]
                    return license
                  }
                })
                if (key == 'other') return;
                if ((props.details?.extra_info?.captured || {}).hasOwnProperty(key)) {
                    url = props.details?.extra_info?.captured[key]
                }
                if ((props.details?.extra_info?.damagedDetection || {}).hasOwnProperty(key)) {
                    url = props.details?.extra_info?.damagedDetection[key]
                }
                return (
                  <>
                    <Box textAlign='center' mr={1}>
                      {key == 'frontRight' && <small>Trước - Phải</small>}
                      {key == 'frontLeft' && <small>Trước - Trái</small>}
                      {key == 'backLeft' && <small>Sau - Trái</small>}
                      {key == 'backRight' && <small>Sau - Phải</small>}
                      <Box
                        onClick={(e) => actionImageUpload(url)}
                        style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                        {!+url ? <img src={url} width="100%" height="100%" /> : null}
                      </Box>
                      <small
                        style={{ color: license == props?.details?.private_key.toLowerCase() ? '#39B44A' : '#FF0000' }}
                      >
                        Biển số:&nbsp; <span style={{ textTransform: 'uppercase' }}>{license}</span>
                      </small>
                    </Box>
                  </>
                )
              })}
              <Box hidden={!props.details?.extra_info?.captured?.other} mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box textAlign='center'>
                  <small>Tem kiểm định</small>
                  <Box
                    onClick={(e) => actionImageUpload(props.details?.extra_info?.captured?.other)}
                    style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                    <img src={props.details?.extra_info?.captured?.other} width="100%" height="100%" />
                  </Box>
                </Box>
              </Box>
              {(props.details?.extra_info?.damaged?.frontLeft || []).map((v, i) => (
                <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box textAlign='center'>
                    {v?.url ? <small>Trước - Trái</small> : null}
                    <Box
                      onClick={(e) => actionImageUpload(v?.url)}
                      style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                      {v?.url ? <img src={v?.url} width="100%" height="100%" /> : null}
                    </Box>
                  </Box>
                </Box>
              ))}
              {(props.details?.extra_info?.damaged?.frontRight || []).map((v, i) => (
                <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box textAlign='center'>
                    {v?.url ? <small>Trước - Phải</small> : null}
                    <Box
                      onClick={(e) => actionImageUpload(v?.url)}
                      style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                      {v?.url ? <img src={v?.url} width="100%" height="100%" /> : null}
                    </Box>
                  </Box>
                </Box>
              ))}
              {(props.details?.extra_info?.damaged?.backLeft || []).map((v, i) => (
                <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box textAlign='center'>
                    {v?.url ? <small>Sau - Trái</small> : null}
                    <Box
                      onClick={(e) => actionImageUpload(v?.url)}
                      style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                      {v?.url ? <img src={v?.url} width="100%" height="100%" /> : null}
                    </Box>
                  </Box>
                </Box>
              ))}
              {(props.details?.extra_info?.damaged?.backRight || []).map((v, i) => (
                <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box textAlign='center'>
                    {v?.url ? <small>Sau - Phải</small> : null}
                    <Box
                      onClick={(e) => actionImageUpload(v?.url)}
                      style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                      {v?.url ? <img src={v?.url} width="100%" height="100%" /> : null}
                    </Box>
                  </Box>
                </Box>
              ))}
              {(Object.keys(props.details?.extra_info?.manualDamaged || {})).map((key, i) => (
                props.details?.extra_info?.manualDamaged[key] && props.details?.extra_info?.manualDamaged[key]?.length > 0 ?
                  (props.details?.extra_info?.manualDamaged[key] || []).map((v, i) => (
                    <>
                      <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box textAlign='center'>
                          {v ? <small>Hình bổ sung</small> : null}
                          <Box
                            onClick={(e) => actionImageUpload(v)}
                            style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                            <img src={v} width="100%" height="100%" />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )
                  ) : <></>
              ))}
              {/* {(Object.keys(props.details?.extra_info?.manualDamaged || {})).map((key, i) => (
                props.details?.extra_info?.manualDamaged[key] && props.details?.extra_info?.manualDamaged[key]?.length > 0 ?
                  (props.details?.extra_info?.manualDamaged[key] || []).map((v, i) => (
                    <>
                      <Box mr={1} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box textAlign='center'>
                          {v ? <small>Hình bổ sung</small> : null}
                          <Box
                            onClick={(e) => actionImageUpload(v)}
                            style={{ border: '1px solid #C4C4C4', background: '#fff', borderRadius: '8px', width: '108px', height: '72px', display: 'flex', justifyContent: 'center', alignCenter: 'center', position: 'relative' }}>
                            <img src={v} width="100%" height="100%" />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )
                  ) : <></>
              ))} */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {modalImageUpload && (
        <ModalImageUpload
          idShow={modalImageUpload}
          setShow={setModalImageUpload}
        />
      )}
    </>
  );
};
