
import { useEffect, useState, useContext } from 'react';
import { Box, Container, Button, Stack, Typography } from '@mui/material';
import {
  Link,
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { message } from 'antd';

import { AccountInfo } from '../components/account-manage-update/account-info';
import { AccountGlobalCare } from '../components/account-manage-update/account-global-care';
import { AccountGara } from '../components/account-manage-update/account-gara';
import { AccountRole } from '../components/account-manage-update/account-role';
import { AccountInsuranceSystem } from '../components/account-manage-update/account-insurance-system';
import { AccountSubMenu } from '../components/account-manage-update/account-sub-menu';
import { AccountSubClass } from '../components/account-manage-update/account-sub-class';
import {
  formValidate,
  phoneValidate2,
  emailValidate,
  passwordValidate,
  costValidate,
  requiredValidField
} from '../../../../utils/validation/input'
import {
  updateAccount,
} from '../../../../apis/adminManagerAccountApis';
import { getProfile } from '../../../../apis/authApis'
import { getListAccountGlobalcare } from '../../../../apis/adminManagerAccountApis'
import { messages } from '../../../../utils/messages';
import * as PATH from '../../../../configs/routesConfig';
import { ModalLoading } from '../../../../ui/modal';
import moment from 'moment';
import { SeverityPill } from '../components/severity-pill';

const initListData2 = [
  { label: 'MIC', value: '1' },
  { label: 'PTI', value: '2' },
  { label: 'VNI', value: '3' },
]
const initListData = [
  { label: 'Global Care', value: '1' },
  { label: 'Công ty bảo hiểm', value: '2' },
  { label: 'Merchant', value: '3' },
]

const contants_status = {
  1: { value: 'completed', label: 'Hoạt động', },
  2: { value: 'rejected', label: 'Khoá', },
}
const AccountUpdate = (props) => {
  const history = useHistory()
  const location = useLocation();
  const { id } = useParams()
  const userId = id || false
  const roleEdit = location?.state?.roleEdit || false
  const user = JSON.parse(localStorage.getItem('user')) || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && user?.extra_info?.userType) || 0
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [productCode, setProductCode] = useState(null);
  const [render, setRender] = useState(false);
  const [accountDetails, setAccountDetails] = useState({})
  const validationSchema = (values) => yup.object().shape({
    fullname: formValidate,
    username: formValidate && yup.string().min(6, 'Vui lòng nhập ít nhất 6 ký tự'),
    email: emailValidate,
    phone: phoneValidate2,
    roleId: formValidate,
    insuranceIncId: values.roleId == 2 && formValidate,
  });

  const formik = useFormik({
    validationSchema: props => yup.lazy(validationSchema),
    onSubmit: (values) => {
      handleSubmitData(values)
    },
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setSubmitting,
    isSubmitting,
    setValues
  } = formik

  const [roleSubMenu, setRoleSubMenu] = useState([]);

  const [errorSelectGara, setErrorSelectGara] = useState('')
  const [errorSubMenu, setErrorSubMenu] = useState('')
  const [permissionAccount, setPermissionAccount] = useState()
  const [permissionManage, setPermissionManage] = useState()
  const [details, setDetails] = useState({})

  useEffect(() => {
    fetchListAccountFromGlobalCare({ username: id })
  }, [])

  const fetchListAccountFromGlobalCare = async (params) => {
    getListAccountGlobalcare(params).then(res => {
      const data = res?.data?.result?.data[0] || {}
      const total = res?.data?.result?.total || 0
      const arrPermissionAccount = []
      setDetails({ ...data })
      setValues({
        fullname: data?.fullname,
        username: data?.username,
        email: data?.email,
        phone: data?.phone_number,
        lastLogin: data?.updated_date,
        insuranceIncId: data?.extra_info?.merchant_ai?.insuranceIncId,
        roleId: data?.extra_info?.merchant_ai?.roleId,
        agencyName: data?.extra_info?.merchant_ai?.agencyName,
        status: +data?.status,
      })
      if (data?.extra_info?.merchant_ai?.permission?.portalAI?.account?.create_edit == 1) {
        arrPermissionAccount.push('create_edit')
      }
      if (data?.extra_info?.merchant_ai?.permission?.portalAI?.account?.export == 1) {
        arrPermissionAccount.push('export')
      }
      handleSelectRoleAccount([...arrPermissionAccount])
      handleSetPermissionManage({
        fullAccess: data?.extra_info?.merchant_ai?.permission?.portalAI?.fullAccess,
        search: data?.extra_info?.merchant_ai?.permission?.portalAI?.search
      })
    })
      .catch(err => {
        console.error(err)
      })
  }
  const handleSelectRoleAccount = (e) => {
    setPermissionAccount({
      create_edit: e?.includes('create_edit') ? 1 : 2,
      export: e?.includes('export') ? 1 : 2,
    })
  }

  const handleSetPermissionManage = (e) => {
    setPermissionManage(e)
  }

  const validateSubmenuAllNull = () => {
    return true
  }

  const handleSubmitData = async () => {
    const {
      fullname,
      username,
      email,
      phone,
      password,
      manager,
      agencyName,
      insuranceIncId,
      roleId
    } = values
    if (validateSubmenuAllNull()) {
      // ModalLoading.show()
      let params = {
        type: 1,
        fullname,
        phone_number: phone,
        extra_info: {
          merchant_ai: {
            roleId,
            roleName: initListData[roleId-1].label,
            insuranceIncName: insuranceIncId ? initListData2[insuranceIncId-1].label : '',
            insuranceIncId,
            agencyName,
            level: details?.extra_info?.merchant_ai?.level || 1,
            permission: {
              portalAI: {
                ...permissionManage,
                account: permissionAccount
              },
            }
          },
        }
      }
      updateAccount(details?.id, params).then(res => {
        getProfile().then(res => {
          localStorage.setItem('user', JSON.stringify(res.data.result));
          localStorage.setItem('permission', JSON.stringify({ ...res.data.result?.extra_info?.merchant_ai?.permission }))
        }).then(() => updateSuccess());
      })
        .catch(err => {
          updateFail(err?.response?.data)
        })
    } else {
      setSubmitting(false)
    }
  }

  const updateSuccess = async () => {
    setSubmitting(false);
    ModalLoading.hide()
    history.push(PATH.ADMIN_ACCOUNT_MANAGE)
    message.success({
      content: userId ?
        messages.alert.update_account_success :
        messages.alert.create_account_success,
      duration: 5
    })
  }

  const updateFail = (error) => {
    ModalLoading.hide()
    setSubmitting(false);
    message.error({
      content: userId ?
        messages.alert.update_account_error :
        messages.alert.create_account_error,
      duration: 5
    })
  }


  return (
    <>
      {
        <form onSubmit={handleSubmit}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pt: 8
            }}
          >
            <Container maxWidth="lg" style={{ marginBottom: 50 }}>
              <Typography
                sx={{ mb: 1 }}
                variant="h4"
              >
                {`${details.fullname} - User đăng nhập: ${details.username}`}
              </Typography>
              <SeverityPill
                color={contants_status[+details?.status || 1].value}
              >
                {contants_status[+details?.status || 1].label}
              </SeverityPill>
              <AccountInfo
                setValues={setValues}
                details={details}
                values={values}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                systemType={systemType}
                errorSelectGara={errorSelectGara}
                setIsChangePassword={setIsChangePassword}
              />

              {permissionManage && <AccountGara
                title='Quyền Quản Lý'
                handleSelectRole={handleSetPermissionManage}
                permissionManage={permissionManage}
              />
              }
              {(details?.extra_info?.merchant_ai?.level || 0) < 3 && <AccountRole
                handleSelectRole={handleSelectRoleAccount}
                title='Quyền Quản Lý Tài Khoản'
                permissionAccount={permissionAccount}
              />
              }
              {errorSubMenu ? (
                <ErrorSubMenu>{errorSubMenu}</ErrorSubMenu>
              ) : null}
              <ButtonBottom
                type='submit'
                color="gc"
                // loading={isSubmitting}
                variant="contained"
                style={{ height: 56, width: 246 }}
                loadingPosition="start"
              // disabled={isSubmitting}
              >
                Cập nhật
              </ButtonBottom>
              <div style={{ height: 100 }} />
            </Container>
          </Box>
        </form>
      }
    </>

  )
};

const ButtonBottom = styled(LoadingButton)(() => ({
  height: 56,
  width: 246,
  margin: 'auto',
  display: 'flex',
  top: 60,
}));

const ErrorSubMenu = styled(Typography)(() => ({
  fontSize: 16,
  color: 'red',
  textAlign: 'center',
  marginTop: 20
}));

export default AccountUpdate
