import React from 'react';
import {
    Box,
    Modal,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
// import Helper from '../../../../../extra/helper_pti_tndsxemay';

const useStyles = makeStyles((theme) => ({
    conmodal: {
        alignItems: 'center',
        display: 'flex',
        position: 'fixed',
        zIndex: 1300,
        inset: '0px',
        padding: '20px',
        justifyContent: 'center',
        
    },
}));
export default function ModalSuccess({code = null, setOpen, show, choosePackage}) {
    const classes = useStyles();

    const goHome = () => {
        window.location.reload();
    }
    
    const goCard = () => {
        // var platform = Helper.getParamUrlPlatform();
        // if (platform === 'app') {
        //   History.replace('/gotocart/' + code);
        //   window.location.reload();
        // }
      }

    const onClose = () => {
        setOpen({show: false })
    }

    const renderDes = () => {
        if (choosePackage['4']['checked'] && choosePackage['27']['checked']){
            return <>Đơn hàng Bảo hiểm <b>TNDS bắt buộc ô tô và Bảo hiểm vật chất xe ô tô</b> đã được thêm vào giỏ hàng <b>thành công.</b></>
        }
        if (choosePackage['4']['checked'] && !choosePackage['27']['checked']){
            return <>Đơn hàng <b>Bảo hiểm TNDS bắt buộc Ô tô</b> đã được thêm vào giỏ hàng <b>thành công.</b></>
        }
        if (choosePackage['27']['checked'] && !choosePackage['4']['checked']){
            return <>Đơn hàng <b>Bảo hiểm Vật chất xe ô tô</b> đã được thêm vào giỏ hàng <b>thành công.</b></>
        }
        return ''
    }

    return (
        <Modal
            open={show}
            // onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={clsx(classes.conmodal)}
        >
            <Box style={{ border: 'none', outline: 'none', backgroundColor: 'white', padding: '25px 25px', borderRadius: 20, maxWidth: 500 }}>
                <div style={{ textAlign: 'center', paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>
                    <div><img src="/core_tnds+vcx_oto/title_success.svg" /></div>
                    <div style={{ marginTop: 20, fontWeight: 400, fontSize: 16, color: '#515855' }}>
                        {renderDes()}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 20 }}>
                        <div onClick={goHome}><img src="/core_tnds+vcx_oto/btnback_success.svg" /></div>
                        <div onClick={goCard}><img src="/core_tnds+vcx_oto/btncart_success.svg" /></div>
                    </div>
                </div>
            </Box>
        </Modal>

    );
}