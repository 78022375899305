import moment from 'moment'
import {
  Link, useLocation
} from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Badge,
  Tooltip
} from '@mui/material';
import { Table } from "antd";
import { SeverityPill } from '../severity-pill';
import iconDownload from '../../assets/icons/iconDownload.svg';
import * as PATH from '../../../../../configs/routesConfig';
import {
  exportFileFromProvider,
  exportFileFromGlobalCare
} from '../../../../../apis/exportClaimApis';
import { downloadFile } from '../../../../../utils/downloadFile';
import { Excel } from "antd-table-saveas-excel";

export const BusinessReportTable = ({
  data = [],
  isLoading = false,
  productId = null,
  roleMenu,
  dataFilter = null
}) => {
  const location = useLocation()
  let key = location?.pathname?.split('/')[3];
  const user = JSON.parse(localStorage.getItem('user')) || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && user?.extra_info?.userType) || 0
  console.log('user?.extra_info?.merchant_ai?.permission?.portalAI?.account?.export', user?.extra_info?.merchant_ai?.permission?.portalAI?.account?.export)
  const columns = [
    {
      title: "#",
      excelRender: (value, row, index) => index + 1,
      render: (value, record, index) => {
        return index + 1
      },
    },
    {
      title: "Mã hồ sơ",
      dataIndex: 'code',
    },
    {
      title: "Biển số",
      dataIndex: 'private_key',
    },
    {
      title: "Ngày tạo link",
      dataIndex: 'created_date',
      excelRender: (value, row, index) => moment(value).format('DD/MM/YYYY'),
      render: (value, add) => {
        return moment(value).format('DD/MM/YYYY')
      },
    },
    {
      title: "Ngày link hết hạn",
      dataIndex: 'created_date',
      excelRender: (value, row, index) => moment(value).add(7, 'days').format('DD/MM/YYYY'),
      render: (value, add) => {
        return moment(value).add(7, 'days').format('DD/MM/YYYY')
      },
    },
    {
      title: "Ngày hoàn tất chụp",
      dataIndex: 'updated_date',
      excelRender: (value, row, index) => !value ? '' : moment(value).format('DD/MM/YYYY'),
      render: (value, add) =>
        !value ? '' : moment(value).format('DD/MM/YYYY')
      ,
    },
    {
      title: "Nhân sự tạo link",
      dataIndex: 'fullname',
      render(text, record) {
        return {
          props: {
            style: { textTransform: "capitalize" }
          },
          children: <div>{text}</div>
        };
      },
      excelRender: (value, row, index) => value
    },
    {
      title: "Tình trạng",
      dataIndex: 'status',
      render(text, record) {
        return (
          <SeverityPill
            color={contants_status[text].value}
          >
            {contants_status[text].label}
          </SeverityPill>
        )
      },
      excelRender: (value, row, index) => contants_status[value].label
    },
    {
      title: "Chi tiết HS",
      render: (value) => {
        const params = value.certNum + '_' + value.productIdParent
        return (
          <Link
            to={`/admin/search/detail/${value.code}`}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9866 29.9999C23.2636 29.9999 29.9734 23.2843 29.9734 15C29.9734 6.71574 23.2636 0 14.9866 0C6.70961 0 -0.000244141 6.71574 -0.000244141 15C-0.000244141 23.2843 6.70961 29.9999 14.9866 29.9999Z" fill="#FB7800" />
              <path d="M14.9841 17.6828C16.4679 17.6828 17.6708 16.4789 17.6708 14.9938C17.6708 13.5086 16.4679 12.3047 14.9841 12.3047C13.5002 12.3047 12.2974 13.5086 12.2974 14.9938C12.2974 16.4789 13.5002 17.6828 14.9841 17.6828Z" fill="white" />
              <path d="M25.0192 14.7679C24.2292 12.7227 22.8577 10.9539 21.0743 9.68027C19.2908 8.40664 17.1736 7.68396 14.9843 7.60156C12.795 7.68396 10.6778 8.40664 8.89434 9.68027C7.11091 10.9539 5.73944 12.7227 4.94944 14.7679C4.89609 14.9156 4.89609 15.0774 4.94944 15.2251C5.73944 17.2704 7.11091 19.0391 8.89434 20.3127C10.6778 21.5864 12.795 22.3091 14.9843 22.3915C17.1736 22.3091 19.2908 21.5864 21.0743 20.3127C22.8577 19.0391 24.2292 17.2704 25.0192 15.2251C25.0725 15.0774 25.0725 14.9156 25.0192 14.7679ZM14.9843 19.3663C14.1208 19.3663 13.2767 19.11 12.5587 18.6298C11.8408 18.1497 11.2812 17.4672 10.9507 16.6687C10.6203 15.8703 10.5338 14.9917 10.7023 14.144C10.8708 13.2964 11.2866 12.5178 11.8971 11.9066C12.5077 11.2955 13.2857 10.8793 14.1326 10.7107C14.9795 10.5421 15.8573 10.6287 16.6551 10.9594C17.4528 11.2901 18.1347 11.8502 18.6144 12.5688C19.0942 13.2874 19.3502 14.1323 19.3502 14.9965C19.3484 16.1549 18.8879 17.2653 18.0695 18.0844C17.2511 18.9035 16.1417 19.3645 14.9843 19.3663Z" fill="white" />
            </svg>

          </Link>
        )
      },
      excelRender: (value, row, index) => {}
    },
  ];

  const exportData = () => {
    const excel = new Excel();
    excel
      .addSheet("Hồ sơ")
      .addColumns(columns)
      .addDataSource(data, {
        str2Percent: true
      })
      .saveAs("HoSoChupHinh.xlsx");
  }

  return (
    <Box sx={{ minWidth: 800 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h5"
          my={4}
        >
          {
            dataFilter
              ? <>Kết quả tra cứu &nbsp;&nbsp;
                <span>
                  <Badge color="success" variant='dot'></Badge> &nbsp;
                  <small style={{fontSize: 15, color: 'grey', fontWeight: '400'}}>{data?.length || 0} kết quả</small>
                </span>
              </>
              : ""
          }
        </Typography>
        <Button
          hidden={!dataFilter || user?.extra_info?.merchant_ai?.permission?.portalAI?.account?.export != 1}
          color="primary"
          onClick={exportData}
        >
          <img alt='iconDownload' src={iconDownload} /> &nbsp; Xuất báo cáo
        </Button>
      </Box>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
      />
    </Box>
  )
};

const contants_status = {
  1: { value: 'new', label: 'Chờ chụp hình' },
  2: { value: 'completed', label: 'Thành công', },
  3: { value: 'rejected', label: 'BKS chưa khớp', },
  4: { value: 'pending', label: 'Link hết hạn', }
}
