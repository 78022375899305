import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import api from "../../../../../configs/api";
import Swal from 'sweetalert2'

import ButtonGetLink from '../../assets/images/Frame 1000002560.png'
import bgBanner from '../../assets/images/bgBanner.png'

// import bgSearch from '../../assets/images/bgSearch.png'
import btnCopy from '../../assets/images/Frame 1000002622.svg'
import iconCopy from '../../assets/images/akar-icons_link-chain.svg'
// import Swal from 'sweetalert2'

export const BusinessReportSearch = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [link, setLink] = useState('');

  const handleChange = (event) => {
    setError(false)
    setValue({
      ...value,
      [event.target.name]: event.target.value
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!value?.code) return setError(true)
    if (value?.code < 8 || value?.code > 10) return setError(true)
      api.apiForm('GET', `/api/v1/mc-ai/get-link?private-key=${value?.code}`)
      .then((response)=>{
        if (response?.data?.statusCode == 200) {
          return setLink(response?.data?.result?.link.replaceAll('\n', '').replaceAll(' ', ''))
        }
        return Swal.fire({
          imageUrl: '/warning.png',
          imageWidth: 82,
          imageHeight: 'auto',
          imageAlt: 'Custom image',
          title: 'Lưu ý',
          text: response?.response?.data?.result?.status == 2 ? "Biển số xe này đã chụp hình thành công" : 'Biển số xe này đã được tạo link và đang chờ hoàn tất',
          confirmButtonText: 'Đến link đã tạo',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            console.log('e', response)
            window.location = response?.response?.data?.result?.link
          }
        })
      })}

  const copy = () => {
    window.navigator.clipboard.writeText(link)
    return alert('Sao chép thành công')
  }



  function _validateCarNumber(bsx) {
    var bsx_valid = bsx;
    var splitbsx = bsx_valid.split("-");
    const re = /^[0-9]{2}[A-Za-z]{1}$/;
    // console.log(re.test(splitbsx[0]));
    if (bsx_valid.slice(2, 4) == 'LD') {
      return true;
    }
    if (!re.test(splitbsx[0])) {
      return false;
    }
    if (splitbsx.length < 2) {
      return false;
    }
    var splitbsxII = splitbsx[1].split(".").join("");
    if (splitbsxII.length > 5 || splitbsxII.length < 4) {
      return false;
    }

    var numbers = "^[0-9]*$";
    if (!splitbsxII.match(numbers)) {
      return false;
    }
    if (splitbsx[0].length > 4 || splitbsx[0].length < 3) {
      return false;
    }
    return true;
  };

  return (
    <form
      style={{
        background: `url(${bgBanner})`,
        backgroundSize: '100% auto',
        borderRadius: 16,
      }}
      onSubmit={submit}
    >
      <Grid
        p={"1rem"}
        container
        spacing={3}
        style={{ alignItems: 'center' }}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            display="flex"
            fullWidth
            mb={1}
            fontWeight={600}
            fontSize={22}
            color="#FFF"
          >
            Lấy link ảnh
          </Typography>
          <br />
          <Box
            display="flex"
            alignItems="center"
          >
            <TextField
              fullWidth
              placeholder="Nhập biển số xe"
              label="Biển số xe"
              name="code"
              onChange={handleChange}
              value={value?.code || ""}
              variant="outlined"
              id="code-custom"
              focused
            />
            &nbsp;&nbsp;
            <Button
              type='submit'
              fullWidth
              color="gc"
              variant="contained"
              sx={{ height: "100%", maxWidth: 100, padding: "17px" }}
            >
              Lấy link
            </Button>
          </Box>
          {error ? <small style={{ color: 'red' }}>Biển số xe không đúng</small> : <></>}
          <Box height="15px"> </Box>

          <Box hidden={Boolean(link)}>
            <img src={ButtonGetLink} width="100%" />
          </Box>

          <Box
            hidden={!Boolean(link)}
            onClick={copy}
            style={{ background: 'white', display: 'flex', alignItems: 'center', height: 45, borderRadius: 8 }}>
            &nbsp;&nbsp;
            <img src={iconCopy} height="36px" />
            &nbsp;&nbsp;
            <Box
              width="calc(100% - 70px)"
              component="a"
              href={link}
              target="_blank"
              style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {link}
            </Box>
            <img src={btnCopy} height="100%" />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
