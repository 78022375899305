import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { updateProfile } from '../../../../../apis/authApis'
import { messages } from '../../../../../utils/messages';
import { message } from 'antd';
import moment from 'moment'

export const AccountProfileDetails = ({ profiles, loadProfiles }) => {
  const [values, setValues] = useState({});
  useEffect(() => {
    setValues({ ...profiles })
  }, [profiles])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const data = {
      type: 1,
      "fullname": values.fullname,
      "email": values.email,
      "phone_number": values.phone_number,
      "private_id": values.private_id,
      "extra_info": {
        "dob": values.dob,
        "address": values.address
      }
    }
    updateProfile(data).then((v) => {
      message.success({
        content:messages.alert.update_account_success,
        duration: 5
      })
      loadProfiles();
    })
  };

  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="Họ và tên"
            name="fullname"
            onChange={handleChange}
            required
            value={values?.fullname}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            disabled
            fullWidth
            label="User đăng nhập"
            name="username"
            InputLabelProps={{ shrink: true, required: true }}
            // type="date"
            onChange={handleChange}
            required
            value={values.username || values?.extra_info?.username}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="Số điện thoại"
            name="phone_number"
            onChange={handleChange}
            type="number"
            value={values?.phone_number}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            disabled
            fullWidth
            label="Cấp bậc tài khoản"
            name="private_id"
            onChange={handleChange}
            required
            value={values?.extra_info?.merchant_ai?.level ? `Cấp ${values?.extra_info?.merchant_ai?.level}` : 'Admin'}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="Email"
            name="email"
            onChange={handleChange}
            required
            value={values?.email}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="Ngày tạo"
            name="address"
            onChange={handleChange}
            required
            value={moment(values?.created_date).format('DD/MM/YYYY')}
            disabled
            variant="outlined"
            focused
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 2,
          mt: "calc(100vh - 650px)"
        }}
      >
        <Button
          onClick={handleSubmit}
          sx={{ padding: "10px 60px" }}
          color="gc"
          variant="contained"
        >
          Cập nhật
        </Button>
      </Box>
    </form>
  );
};
