import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled, TextField, Box, MenuItem } from '@mui/material';
import { Checkbox } from 'antd';

import {
  getConfigAll
} from '../../../../../apis/adminManagerAccountApis';

import './account-manager.css'

export const AccountSubClass = ({
  onChangeSubClassAccountValue = () => { },
  subClassAccount,
  title = '',
  handleChange,
  touched,
  errors,
  values,
  roleData = [],
  userId = null,
  roleEdit = false
}) => {

  const [levels, setLevels] = useState([])

  const [value, setCheckedList] = useState(null);

  useEffect(() => {
    getConfigAll().then((v) => {
      if (v.status == 200) {
        setLevels(v.data.result.level)
      }
    })
  }, [])

  const onChange = (e) => {
    let value;
    const [section, key] = e.target.name.split(".");
    if (key) {
      value = { system: { ...subClassAccount?.system, [key]: e.target.value } }
    } else {
      value = { [e.target.name]: e.target.value }
    }
    onChangeSubClassAccountValue(e.target.name, value)
  }

  return (
    <>
      <Item
        id='manager-global-care'
      >
        <ItemInfo
          alignItems="center"
          justify="center"
        >
          <ItemTitle>
            {title}
          </ItemTitle>
        </ItemInfo>
        <Box
          hidden={values.manager === 3}
        >
          <Title>
            Phân cấp hệ thống
          </Title>
          <Box
            p={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box style={{ width: "50%" }}>
              <TextField
                fullWidth
                placeholder="Nhập giá trị"
                label="Mức duyệt tối thiểu"
                name="approvalValueFrom"
                onChange={handleChange}
                value={values?.approvalValueFrom}
                variant="outlined"
                focused
                error={touched.approvalValueFrom && Boolean(errors.approvalValueFrom)}
                helperText={touched.approvalValueFrom && errors.approvalValueFrom}
              />
            </Box>
            <Box mx={2}>
              đến
            </Box>
            <Box style={{ width: "50%" }}>
              <TextField
                fullWidth
                placeholder="Nhập giá trị"
                label="Mức duyệt tối đa"
                name="approvalValueTo"
                onChange={handleChange}
                value={values?.approvalValueTo}
                variant="outlined"
                focused
                error={touched.approvalValueTo && Boolean(errors.approvalValueTo)}
                helperText={touched.approvalValueTo && errors.approvalValueTo}
              />
            </Box>
          </Box>
        </Box>
        <Box
          hidden={values.manager != 3}
        >
          <Title>
            Phân cấp tài khoản
          </Title>
          <Box
            p={4}
            width="50%"
          >
            <TextField
              select
              fullWidth
              placeholder="Nhập giá trị"
              label="Mức duyệt tối đa"
              name="levelId"
              onChange={handleChange}
              value={values?.levelId}
              variant="outlined"
              focused
              error={touched.levelId && Boolean(errors.levelId)}
              helperText={touched.levelId && errors.levelId}
            >
              {levels.map((v) => (
                <MenuItem key={v.id} value={v.id}>{v.title}</MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            px={4}
            pb={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box style={{ width: "50%" }}>
              <TextField
                fullWidth
                placeholder="Nhập giá trị"
                label="Mức duyệt tối thiểu"
                name="system_approvalValueFrom"
                onChange={handleChange}
                value={values?.system_approvalValueFrom}
                variant="outlined"
                focused
                error={touched.system_approvalValueFrom && Boolean(errors.system_approvalValueFrom)}
                helperText={touched.system_approvalValueFrom && errors.system_approvalValueFrom}
              />
            </Box>
            <Box mx={2}>
              đến
            </Box>
            <Box style={{ width: "50%" }}>
              <TextField
                fullWidth
                placeholder="Nhập giá trị"
                label="Mức duyệt tối thiểu"
                name="system_approvalValueTo"
                onChange={handleChange}
                value={values?.system_approvalValueTo}
                variant="outlined"
                focused
                error={touched.system_approvalValueTo && Boolean(errors.system_approvalValueTo)}
                helperText={touched.system_approvalValueTo && errors.system_approvalValueTo}
              />
            </Box>
          </Box>
        </Box>

      </Item>
    </>
  )
};

const FormatMoney = (str) => {
  if (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));

const Title = styled(Typography)(() => ({
  padding: '5px 36px 5px 36px',
  fontSize: 20,
  fontWeight: '600',
  background: '#EBEAEA'
}));
