import { Box, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoginSidebar } from './login-sidebar';
import shadowCenter from '../../assets/images/shadow-center.svg';
import shadowTop from '../../assets/images/shadow-top.svg';

const LoginLayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}));

export const LoginLayout = (props) => {
  const { children } = props;

  return (
    <>
      <LoginLayoutRoot>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
          }}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              md={5}
            >
              <LoginSidebar />
            </Grid>

            <Grid
              item
              xs={12}
              md={7}
            >
              {/* <ShadowCenter /> */}
              <ShadowTop />
              {children}
            </Grid>
          </Grid>
        </Box>
      </LoginLayoutRoot>
    </>
  );
};


const ShadowCenter = () => {
  return (
    <Box
      width="70vw"
      height="50vh"
      top="30vh"
      left="20vw"
      position="absolute"
    >
      <img width="50%" src={shadowCenter} />
    </Box>
  )
}

const ShadowTop = () => {
  return (
    <Box
      width="20vw"
      height="20vh"
      top="0"
      right="0"
      position="absolute"
    >
      <img width="100%" src={shadowTop} />
    </Box>
  )
}

