import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { theme } from "../views/admin/globalcare/theme/index";
import History from "../configs/history";
import * as PATH from "../configs/routesConfig";
import AdminLogin from "../views/admin/globalcare/page/login";
import ForgotPasswordLogin from "../views/admin/globalcare/page/password-forgot";
import ChangePasswordLogin from "../views/admin/globalcare/page/password-change";
import Home from "../views/admin/globalcare/page/home";
import CarInsuranceDashboard from "../views/admin/globalcare/page/dashboard";
import { DashboardLayout } from "../views/admin/globalcare/components/layout-dashboard/dashboard-layout";
import HomeIndex from "../views/client/vcx-oto/order/index";
import Account from "../views/admin/globalcare/page/account";
import BusinessTable from "../views/admin/globalcare/page/business-report-table";
import ClaimDetails from "../views/admin/globalcare/page/business-detail";
import GetLink from "../views/admin/globalcare/page/business-report-table copy";
import AccountManage from "../views/admin/globalcare/page/account-manage";
import AccountCreate from "../views/admin/globalcare/page/account-manage-create";
import AccountUpdate from "../views/admin/globalcare/page/account-manage-update";

const redirectTo = (user) => {

  return PATH.ADMIN;
};

const AppRouter = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
  }, [])
  if (History.location.pathname != '/'){
    {
      !isTokenFound &&
        Notification.requestPermission()
    }
  }
  return (
    <Router history={History}>
      <Route exact path={PATH.APP_PATH} key={PATH.APP_PATH} component={HomeIndex} />
      <Route path={PATH.ADMIN_PATH} key={PATH.ADMIN_PATH} component={Admin} />
    </Router>
  );
};

export default AppRouter;

const Admin = () => {
  const user = localStorage.getItem("user") || false;
  const isCheckUser = user && user !== "undefined" && user !== "null";
  const systemType =
    (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0;
  const token = localStorage.getItem("token") || false;
  const [listMenu, setListMenu] = useState([true]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route
            exact
            path={PATH.ADMIN_LOGIN}
            component={AdminLogin}
            key={PATH.ADMIN_LOGIN}
          />
          <Route
            exact
            path={PATH.ADMIN_FORGOT_PASSWORD}
            component={ForgotPasswordLogin}
            key={PATH.ADMIN_FORGOT_PASSWORD}
          />
          <Route
            exact
            path={PATH.ADMIN_CHANGE_PASSWORD}
            component={ChangePasswordLogin}
            key={PATH.ADMIN_CHANGE_PASSWORD}
          />

          {token && (
            <DashboardLayout>
              <Route
                exact
                path={PATH.ADMIN}
                component={Home}
                key={PATH.ADMIN}
              />
              <Route
                exact
                path={PATH.ADMIN_HOME}
                component={Home}
                key={PATH.ADMIN_HOME}
              />
              <Route
                exact
                path={PATH.ADMIN_ACCOUNT_MANAGE}
                component={AccountManage}
                key={PATH.ADMIN_ACCOUNT_MANAGE}
              />
              <Route
                exact
                path={PATH.ADMIN_ACCOUNT_MANAGE_CREATE}
                component={AccountCreate}
                key={PATH.ADMIN_ACCOUNT_MANAGE_CREATE}
              />
              <Route
                exact
                path={PATH.ADMIN_ACCOUNT_MANAGE_UPDATE}
                component={AccountUpdate}
                key={PATH.ADMIN_ACCOUNT_MANAGE_UPDATE}
              />
              <Route
                exact
                path={PATH.ADMIN_ACCOUNT}
                component={Account}
                key={PATH.ADMIN_ACCOUNT}
              />
              <Route
                exact
                path={PATH.ADMIN_SEARCH}
                component={BusinessTable}
                key={PATH.ADMIN_SEARCH}
              />
              <Route
                exact
                path={PATH.ADMIN_SEARCH_DETAIL}
                component={ClaimDetails}
                key={PATH.ADMIN_SEARCH_DETAIL}
              />
              <Route
                exact
                path={PATH.ADMIN_SEARCH_GETLINK}
                component={GetLink}
                key={PATH.ADMIN_SEARCH_GETLINK}
              />
            </DashboardLayout>
          )}
          <Redirect
            to={token ? redirectTo(JSON.parse(user)) : PATH.ADMIN_LOGIN}
          />
        </Switch>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
