import { useState, useEffect, cloneElement, isValidElement } from 'react';
import {
  Link, useHistory
} from "react-router-dom";
import { Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { ModalAccount } from './modal/modal-account';
import { ModalNotification } from './modal/modal-notification';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 10,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 300
  }
}));

export const DashboardLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [showModalAccount, setShowModalAccount] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [desNavbar, setDesNavbar] = useState('');
  const [titleNavbar, setTitleNavbar] = useState('');
  const [badgeContent, setBadgeContent] = useState(0);

  const onChangeShowModalAccount = () => setShowModalAccount(!showModalAccount);
  const onChangeShowModalNotification = () => setShowModalNotification(!showModalNotification);
  const history = useHistory();

  useEffect(() => {
    setShowModalAccount(false);
  }, [history.location.pathname]);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={cssLayoutRoot}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>

      <DashboardNavbar
        setShowModalNotification={onChangeShowModalNotification}
        setShowModalAccount={onChangeShowModalAccount}
        onSidebarOpen={() => setSidebarOpen(true)}
        desNavbar={desNavbar}
        titleNavbar={titleNavbar}
        badgeContent={badgeContent}
      />

      <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
      <ModalAccount showModalAccount={showModalAccount} onClose={() => setShowModalAccount(false)} key="ModalAccount" />
      <ModalNotification 
      badgeContent={badgeContent}
      setBadgeContent={setBadgeContent}
      showModalNotification={showModalNotification} 
      onClose={() => setShowModalNotification(false)} key="ModalNotification" />
    </>
  );
};


const cssLayoutRoot = {
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
}
