import { List, ListItem, Divider, ListItemText, ListItemButton, ListItemIcon, Modal, Box } from '@mui/material';
import iconAccountInfo from '../../../assets/icons/iconAccountInfo.svg';
import iconChangePasssword from '../../../assets/icons/iconChangePasssword.svg';
import iconLogout from '../../../assets/icons/iconLogout.svg';
import { ADMIN_ACCOUNT, ADMIN_CHANGE_PASSWORD, ADMIN_LOGIN } from '../../../../../../configs/routesConfig'
import { Link } from 'react-router-dom';

export const ModalAccount = ({ showModalAccount, onClose }) => {
  const arr = [
    {
      name: 'Thông tin tài khoản',
      href: ADMIN_ACCOUNT,
      icon: <img src={iconAccountInfo} />,
    },
    {
      name: 'Đổi mật khẩu',
      href: `/admin/change-password/${JSON.parse(localStorage.getItem('user'))?.username}`,
      icon: <img src={iconLogout} />,
    },
    {
      name: 'Đăng xuất',
      acction: () => onLogout(),
      icon: <img src={iconChangePasssword} />,
      href: ADMIN_LOGIN
    },
  ];

  const onLogout = () => {
    onClose()
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
  return (
    <Modal
      open={showModalAccount || false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={modalAccount}>
        <List>
          {arr.map((v, i) => (
            <Link to={v.href} key={i} style={{color: '#000'}}>
              <ListItemButton
                divider={i < v.length - 1}
                key={i}
                onClick={v.acction}
              >
                <ListItemIcon style={{minWidth: 35}}>
                  {v.icon}
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    </Modal>
  )
}

const modalAccount = {
  position: 'absolute',
  top: '60px',
  right: '10px',
  background: '#fff',
  borderRadius: '14px',
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.07)"
}
