import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';

import iconGC from '../../assets/images/logo-gc.png'
import imageLoginSideBar from '../../assets/images/image-login.png'


const Title = () => {
  return (
    <Typography
      color="textPrimary"
      variant="h5"
      sx={{ color: "#024281", marginTop: 2 }}
    >
      CÔNG TY CỔ PHẦN TƯ VẤN GLOBAL CARE
    </Typography>
  )
}

const LogoGC = () => {
  return (
    <img
      alt='GLOBAL CARE'
      src={iconGC}
      style={{
        width: 120,
        marginBottom: "10px"
      }}
    />
  )
}

const ImageSideBar = () => {
  return (
    <img
      alt='Admin Login'
      src={imageLoginSideBar}
      style={{
        width: "70%",
      }}
    />
  )
}

export const LoginSidebar = (props) => {
  return (
    <>
      <Box
        component="main"
        m={3}
        borderRadius={2}
        sx={mainProps}
      >
        <Box textAlign="center">
          <LogoGC />
          <Title />
        </Box>
        <ImageSideBar />
      </Box>
    </>
  );
};

const mainProps = {
  alignItems: 'center',
  justifyContent: 'space-around',
  display: 'flex',
  flexFlow: 'column',
  flexGrow: 1,
  minHeight: 'calc(100vh - 3rem)',
  background: '#fff'
}


