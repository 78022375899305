import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select, message } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { messages } from '../../../../../utils/messages';
import { format, parseISO } from 'date-fns';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  getListGaraByProvider,
  updatePasswordFromGlobalCare,
  updatePasswordFromProvider,
  updateAccountLock
} from '../../../../../apis/adminManagerAccountApis';
import iconChangePassword from '../../assets/icons/iconChangePassword.svg'
import iconClose from '../../assets/icons/iconClose.svg'
const { Option } = Select;

const initListData2 = [
  { label: 'MIC', value: '1' },
  { label: 'PTI', value: '2' },
  { label: 'VNI', value: '3' },
]
const initListData = [
  { label: 'Global Care', value: '1' },
  { label: 'Công ty bảo hiểm', value: '2' },
  { label: 'Merchant', value: '3' },
]
export const AccountInfo = ({
  setValues,
  values = {},
  touched,
  errors,
  handleChange = () => { },
  handleSelectGara = () => { },
  setIsChangePassword = () => { },
  data = [],
  systemType = 0,
  errorSelectGara = '',
  userId = '',
  roleEdit = false
}) => {
  const [listData, setListData] = useState(initListData)
  const [listData2, setListData2] = useState(initListData2)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowChangePassword, setIsShowChangePassword] = useState(false)
  const [listDataFilter, setListDataFilter] = useState([])
  const [showPassWord, setShowPassWord] = useState(false);
  const user = JSON.parse(localStorage.getItem('user')) || false

  useEffect(() => {
    // if (user?.extra_info?.merchant_ai?.level == 1) {
    //   if (user?.extra_info?.merchant_ai?.roleId == 2) {
    //     return setValues({
    //       roleId: user?.extra_info?.merchant_ai?.roleId,
    //     })
    //   }
    // }
    if (user?.extra_info?.merchant_ai?.level == 1) {
      if (user?.extra_info?.merchant_ai?.roleId == 2) {
        return setValues({
          // agencyName: user?.extra_info?.merchant_ai?.agencyName || 'Test',
          roleId: user?.extra_info?.merchant_ai?.roleId,
          insuranceIncId: user?.extra_info?.merchant_ai?.insuranceIncId,
        })
      }
      else{
        return setValues({
          // agencyName: user?.extra_info?.merchant_ai?.agencyName || 'Test',
          roleId: user?.extra_info?.merchant_ai?.roleId,
        })
      }
    }
    else if (user?.extra_info?.merchant_ai?.level == 2) {
      if (user?.extra_info?.merchant_ai?.roleId == 2) {
        return setValues({
          agencyName: user?.extra_info?.merchant_ai?.agencyName || 'Test',
          roleId: user?.extra_info?.merchant_ai?.roleId,
          insuranceIncId: user?.extra_info?.merchant_ai?.insuranceIncId,
        })
      }
      return setValues({
        roleId: user?.extra_info?.merchant_ai?.roleId,
      })
    }
  }, [])

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const handleShowChangePassword = () => {
    setIsShowChangePassword(!isShowChangePassword)
    setIsChangePassword(!isShowChangePassword)
  }

  const handleChangePassword = () => {
    const params = {
      userId,
      newPassword: values?.newPassword
    }
    if (systemType === 'provider') {
      updatePasswordFromProvider(params).then(res => {
        updateSuccess()
      })
        .catch(err => {
          console.error('err')
          updateFail()
        })
    } else if (systemType === 'globalcare') {
      updatePasswordFromGlobalCare(params).then(res => {
        updateSuccess()
      })
        .catch(err => {
          console.error('err')
          updateFail()
        })
    }
  }

  const handleChangeUserLock = () => {
    const params = {
      userId: userId,
      status: values.status === 1 ? 0 : 1
    }
    updateAccountLock(params).then(res => {
      if (res.data.statusCode == 200) {
        message.success({
          content: values.status === 1 ? 'Khoá tài khoản thành công' : 'Mở khoá tài khoản thành công',
          duration: 5
        })
        setValues({ ...values, status: values.status === 1 ? 0 : 1 });
      }
    })
      .catch(err => {
        console.error('err')
        updateFail()
      })

    return values
  }
  const updateSuccess = async () => {
    setIsShowChangePassword(false)
    message.success({
      content: messages.alert.update_password_success,
      duration: 5
    })
  }

  const updateFail = () => {
    message.error({
      content: messages.alert.update_password_error,
      duration: 5
    })
  }

  return (
    <Grid container style={{ position: 'relative' }}>
      <Item item xs={5.9}>
        <ItemInfo
          alignItems="center"
          justify="center"
        >
          <ItemTitle>
            Thông tin tài khoản
          </ItemTitle>
        </ItemInfo>
        <Grid
          style={{
            padding: 30
          }}
        >
          <TextField
            validateStatus={'false'}
            fullWidth
            placeholder="Nhập họ tên"
            label="Họ tên"
            name="fullname"
            onChange={handleChange}
            value={values.fullname}
            error={touched.fullname && Boolean(errors.fullname)}
            variant="outlined"
            focused
            helperText={touched.fullname && errors.fullname}
            disabled={userId && !roleEdit}
          />
          <TextField
            fullWidth
            placeholder="Nhập user đăng nhập"
            label="User đăng nhập"
            name="username"
            type="text"
            autoComplete='off'
            onChange={handleChange}
            value={values.username}
            variant="outlined"
            focused
            style={{ marginTop: 33 }}
            error={touched.username && Boolean(errors.username)}
            helperText={touched.username && errors.username}
          />
          <TextField
            fullWidth
            placeholder="Nhập Email"
            label="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            variant="outlined"
            style={{ marginTop: 33 }}
            focused
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            disabled={userId && !roleEdit}
          />
          <TextField
            fullWidth
            placeholder="Nhập số điện thoại"
            label="Số điện thoại"
            name="phone"
            onChange={handleChange}
            value={values.phone}
            variant="outlined"
            style={{ marginTop: 33 }}
            focused
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            disabled={userId && !roleEdit}
          />
        </Grid>
      </Item>
      <Grid item xs={0.2} />
      <Item item xs={5.9}>
        <ItemInfo
          alignItems="center"
          justify="center"
        >
          <ItemTitle>
            Quản lý hoạt động tài khoản
          </ItemTitle>
        </ItemInfo>
        <Grid
          style={{
            padding: 30,
          }}
          id='input_select_gara'
        >
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label" hidden={values?.roleId}>
              Chọn hệ thống
            </InputLabel>
            <TextField
              fullWidth
              label="Quyền truy cập hệ thống kênh quản lý"
              name="roleId"
              select
              value={values.roleId}
              onChange={handleChange}
              variant="outlined"
              focused
              error={touched.roleId && Boolean(errors.roleId)}
              helperText={touched.roleId && errors.roleId}
              disabled={+user?.extra_info?.merchant_ai?.level > 0}
            >
              {listData.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: '33px' }} hidden={values.roleId != 2}>
            {!values.insuranceIncId && <InputLabel id="demo-multiple-name-label">
              Chọn công ty bảo hiểm
            </InputLabel>}
            <TextField
              fullWidth
              label="Công ty bảo hiểm"
              name="insuranceIncId"
              select
              value={values.insuranceIncId}
              onChange={handleChange}
              variant="outlined"
              focused
              error={touched.insuranceIncId && Boolean(errors.insuranceIncId)}
              helperText={touched.insuranceIncId && errors.insuranceIncId}
              disabled={+user?.extra_info?.merchant_ai?.level > 1}

            >
              {listData2.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: '33px' }} hidden={(values.roleId != 2 || (+user?.extra_info?.merchant_ai?.level || 0) < 1)}>
            {!values.agencyName && <InputLabel id="demo-multiple-name-label">
              Nhập Chi Nhánh
            </InputLabel>}
            <TextField
              fullWidth
              label="Chi nhánh"
              name="agencyName"
              value={values.agencyName}
              onChange={handleChange}
              variant="outlined"
              focused
              error={touched.agencyName && Boolean(errors.agencyName)}
              helperText={touched.agencyName && errors.agencyName}
              disabled={+user?.extra_info?.merchant_ai?.level > 1}
            >
            </TextField>
          </FormControl>
          <TextField
            fullWidth
            placeholder="Nhập mật khẩu đăng nhập"
            label="Mật khẩu đăng nhập"
            name="password"
            onChange={handleChange}
            value={values.password}
            autoComplete='off'
            variant="outlined"
            style={{ marginTop: 33 }}
            type={showPassWord ? "text" : "password"}
            focused
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              type:"password",
              autoComplete: 'new-password',
              endAdornment: <InputAdornment position="end" onClick={() => setShowPassWord(!showPassWord)}>
                {showPassWord ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>,
            }}
          />
        </Grid>
     
      </Item>

      {isShowChangePassword && (<div
        style={styleChangePassword}
      >
        <HeaderChange>
          <img alt='iconChange' style={{ margin: '0 5px 7px 0' }} src={iconChangePassword} />
          Đổi mật khẩu
          <img
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={handleShowChangePassword}
            src={iconClose}
            alt='iconClose'
          />
        </HeaderChange>
        <TextField
          fullWidth
          placeholder="Nhập Mật khẩu mới"
          label="Mật khẩu mới"
          name="newPassword"
          onChange={handleChange}
          value={values.newPassword}
          type={!isShowPassword ? 'password' : 'text'}
          error={Boolean(errors.newPassword)}
          variant="outlined"
          focused
          helperText={errors.newPassword}
          InputProps={{
            endAdornment:
              isShowPassword ?
                <EyeOutlined onClick={handleShowPassword} /> :
                <EyeInvisibleOutlined onClick={handleShowPassword} />
          }}
        />
        <ButtonBottom
          color="gc"
          // loading={isSubmitting}
          variant="contained"
          style={{ height: 56, width: 246 }}
          loadingPosition="start"
          disabled={!values?.newPassword || errors.newPassword}
          onClick={handleChangePassword}
        >
          Cập nhật
        </ButtonBottom>
      </div>)}
      <Item>
      </Item>
    </Grid>

  )
};

const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));

const HeaderChange = styled(Typography)(() => ({
  position: 'relative',
  fontSize: 23,
  fontWeight: '500',
  color: '#22BD57',
  textAlign: 'center',
  paddingTop: 6,
  marginBottom: 24
}));

const ButtonBottom = styled(LoadingButton)(() => ({
  height: 56,
  width: 246,
  margin: 'auto',
  display: 'flex',
  top: 50,
  borderRadius: 10
}));

const ErrorGara = styled(Typography)(() => ({
  marginLeft: 10,
  fontSize: 14,
  color: 'red'
}));

const IconStatus = styled(Box)(() => ({
  height: 12,
  width: 12,
  marginRight: 10,
  background: '#22BD57',
  borderRadius: 90,
}));

const styleChangePassword = {
  width: 554,
  height: 286,
  background: '#FFF',
  position: 'absolute',
  top: '70%',
  left: '50%',
  right: '50%',
  bottom: '50%',
  margin: 'auto',
  transform: 'translate(-50%)',
  zIndex: 99,
  borderRadius: 18,
  boxShadow: '0px 0px 63px rgba(0, 0, 0, 0.2)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 18
}
