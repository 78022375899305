import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {AccountProfile} from '../components/account/account-profile';
import {AccountProfileDetails} from '../components/account/account-profile-details';
import { login, getProfile } from '../../../../apis/authApis'

const Dashboard = () => {
  const [profiles, setProfiles] = useState({});

  useEffect(()=>{
    loadProfiles();
  }, [])

  const loadProfiles = () => {
    getProfile().then(res => {
      setProfiles({...res.data.result})
      localStorage.setItem('user', JSON.stringify({...res.data.result}))
    });
  } 

  return (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 8
      }}
    >
     <Container maxWidth="lg">
        <Typography
          sx={{ mb: 3 }}
          variant="h4"
        >
          Thông tin tài khoản
        </Typography>
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
          >
            <AccountProfile profiles={profiles} loadProfiles={loadProfiles}/>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{marginBottom: 'auto'}}
          >
            <AccountProfileDetails profiles={profiles} loadProfiles={loadProfiles}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
)};

export default Dashboard;
