import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  useMediaQuery,
  ListItemText,
  ListItemButton,
  Collapse,
  List,
  ListItemIcon,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import iconGC from "../../assets/images/logo-gc.png";
import iconBaoHiemVatChatOto from "../../assets/icons/iconBaoHiemVatChatOto.svg";
import iconManageAccount from "../../assets/icons/iconManageAccount.svg";
import iconProductManage from "../../assets/icons/iconProductManage.svg";
import iconCompanyManage from "../../assets/icons/iconCompanyManage.svg";
import iconMerchantManage from "../../assets/icons/iconMerchantManage.svg";
import iconSubMenu from "../../assets/icons/iconSubMenu.png";
import iconArrowRight from "../../assets/icons/iconArrowRight.svg";
import iconArrowDown from "../../assets/icons/iconArrowDown.svg";

import * as PATH from "../../../../../configs/routesConfig";

export const DashboardSidebar = (props) => {
  const [collapse, setCollapse] = useState([]);
  const [parentCollapse, setParentCollapse] = useState(true);
  const [listMenu, setListMenu] = useState([true]);

  const user = localStorage.getItem("user") || false;
  const permission = JSON.parse(localStorage.getItem("permission")) || false;
  const isCheckUser = user && user !== "undefined" && user !== "null";
  const systemType =
    (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0;
  const roleData =
    (isCheckUser && JSON.parse(user)?.role_management_claim) || 0;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {

  };

  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  let roleMangeAccount = false;
  let roleAccount = roleData?.account && Object.keys(roleData?.account);
  roleAccount?.map((v) => {
    if (roleData?.account[v] !== 0) {
      roleMangeAccount = true;
    }
  });

  const handleClick = (index) => () => {
    let checkCollapse = [];
    checkCollapse[index] = !collapse[index];
    setCollapse(checkCollapse);
  };

  const handleClickParent = () => {
    setParentCollapse(!parentCollapse);
  };

  const renderItemSubmenu = (item, menu) => {
    const roleMenu = roleData?.submenu[0];
    let checkRoleMenu = false;
    let arrayMenu =
      roleMenu &&
      roleMenu[item?.role_type] &&
      Object.keys(roleMenu[item?.role_type]);

    arrayMenu?.map((v) => {
      if (roleMenu[item?.role_type][v] !== 0) {
        checkRoleMenu = true;
      }
    });

    if (!roleMenu?.manage_claim) {
      const roleApprove = Object.keys(roleMenu?.approve_claim).some(
        (e) => !!roleMenu?.approve_claim[e]
      );
      const roleReview = Object.keys(roleMenu?.review_claim).some(
        (e) => !!roleMenu?.review_claim[e]
      );
      if (roleApprove || roleReview) {
        checkRoleMenu = true;
      }
    }

    if (checkRoleMenu) {
      return (
        <Link
          style={{ color: "#121828" }}
          key={item.title}
          to={{
            pathname:
              systemType === "department_garage"
                ? item.href
                : item.href + `/${menu?.product_code}`,
            state: {
              productId: menu?.product_id,
              roleMenu: roleMenu[item?.role_type],
            },
          }}
        >
          <ListItemButton
            sx={{ pl: 4 }}
            selected={window.location.pathname.includes(
              systemType === "department_garage"
                ? item.href
                : item.href + `/${menu?.product_code}`
            )}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </Link>
      );
    }
  };

  const renderParentMenu = (childComponent) => (
    <>
      <ListItemButton onClick={handleClickParent}>
        <ListItemIcon>
          <img src={iconSubMenu} />
        </ListItemIcon>
        <ListItemText primary="Bảo hiểm vật chất ô tô11" />
        {parentCollapse ? (
          <img src={iconArrowDown} />
        ) : (
          <img src={iconArrowRight} />
        )}
      </ListItemButton>
      <Collapse in={parentCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childComponent}
        </List>
      </Collapse>
    </>
  );

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3, float: "center", textAlign: "center" }}>
            <Link to={PATH.ADMIN}>
              <img src={iconGC} height="85" />
            </Link>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <ListItemButton>
            <ListItemIcon style={{ justifyContent: 'center' }}>
              <img src={iconSubMenu} width="auto" height="25px" />
            </ListItemIcon>
            <ListItemText primary="Portal AI" />
            {collapse ? (
              <img src={iconArrowDown} />
            ) : (
              <img src={iconArrowRight} />
            )}
          </ListItemButton>
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* <Link
                to={PATH.ADMIN_ACCOUNT}
                style={{ color: "#121828" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={window.location.pathname.includes(
                    "admin/account"
                  )}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Thông tin tài khoản" />
                </ListItemButton>
              </Link> */}
              {permission?.portalAI?.search?.getLink == 1 && <Link
                to={PATH.ADMIN_SEARCH}
                style={{ color: "#121828" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={window.location.pathname.includes(
                    "search"
                  )}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Kiểm tra xử lý hồ sơ" />
                </ListItemButton>
              </Link>
              }
              {permission?.portalAI?.account?.create_edit == 1 && <Link
                to={{
                  pathname: PATH.ADMIN_ACCOUNT_MANAGE,
                  state: { roleAccount: roleData?.account },
                }}
                style={{ color: "#121828" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={window.location.pathname.includes(
                    "manage"
                  )}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Quản lý tài khoản" />
                </ListItemButton>
              </Link>
              }
            </List>
          </Collapse>
          {/* {permission?.portalAI?.account?.create_edit == 1 && <Link
              to={{
                pathname: PATH.ADMIN_ACCOUNT_MANAGE,
                state: { roleAccount: roleData?.account },
              }}
              style={{ color: "#121828" }}
            >
              <ListItemButton
                selected={window.location.pathname.includes(
                  'manage'
                )}
              >
                <ListItemIcon>
                  <img alt="iconManageAccount" src={iconManageAccount} />
                </ListItemIcon>
                <ListItemText primary="Quản lý tài khoản" />
              </ListItemButton>
            </Link>
            } */}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "fff",
            width: 300,
            color: "#121828",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#121828",
          width: 300,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
