import React, { useRef } from 'react';

import {
  Box,
  Fade,
  Modal,
  Typography,
  Backdrop,
  Divider
} from '@mui/material';

const styleModal = {
  position: "absolute",
  bottom: "0px",
  left: "50%",
  width: "414px",
  transform: 'translate(-50%, 0%)',
  border: "none",
  background: '#fff',
  borderRadius: '16px 16px 0px 0px',
  p: 1
};

export default function ModalPhotographyGuide({ show, setShow }) {
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={show}>
        <Box sx={styleModal}>
          <Box style={{ padding: 16 }}>
            <Box className="flex" style={{ justifyContent: "space-between" }}>
              <Box className="font-weight-600 font-size-16" textAlign="center" width="100%">
                Giá trị bảo hiểm xe (tối đa)
              </Box>
              <Box style={{ textAlign: "right" }} onClick={handleClose}>
                <img src="/core_tnds+vcx_oto/close-button.svg" />
              </Box>
            </Box>
          </Box>
          <Divider />
          <div>
            <p style={{ textAlign: 'left', margin: '0px 20px' }}>
              <h1 style={{ display: 'inline' }}>.</h1> Nếu giá xe không có trong hệ thông vui lòng liên hệ về Hotline: 1900 29 29 87 để được hỗ trợ.
            </p>
          </div>
          <br/>
          <br/>
        </Box>
      </Fade>
    </Modal>
  )
}