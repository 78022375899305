import { Box, Container, Grid, Typography } from '@mui/material';
import { DashboardLayout } from '../components/layout-dashboard/dashboard-layout';

import imgDashboardIntro from '../assets/images/dashboard-intro.png';
import imgDashboardIntro2 from '../assets/images/dashboard-intro-2.png';

const Dashboard = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 8
      }}
    >
      <Container maxWidth={false}>
        <Typography
          fontWeight="fontWeightBold"
          color="textPrimary"
          variant="h4"
          sx={{ marginBottom: 5 }}
        >
          Dashboard
        </Typography>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            sm={6}
            xs={12}
            height="50vh"
          >
            <Box sx={bgDashboardIntro}>
              <img src={imgDashboardIntro} />
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            height="50vh"
          >
            <Box sx={bgDashboardIntro2}>
              <img src={imgDashboardIntro2} />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '30vh' }}>
          <Typography
            color="#024281"
            fontSize="24px"
            sx={{ marginBottom: 1 }}
          >
            Công ty cổ phần Global Care
          </Typography>
        </Box>
        <Box>
          <Typography
            fontSize="18px"
          >
            1/2 Đường số 33, Phường An Khánh, Tp. Thủ Đức
            <br />Hotline: 1900 29 29 87
          </Typography>
        </Box>
      </Container>
    </Box>
  </>
);



export default Dashboard

const bgDashboardIntro = {
  background: 'rgba(236, 140, 27, 0.21)',
  boxShadow: '0px 0px 13.6464px rgba(0, 0, 0, 0.05)',
  borderRadius: '21.8343px',
  height: "100%",
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center'
}

const bgDashboardIntro2 = {
  background: '#C2CEEE',
  boxShadow: '0px 0px 13.6464px rgba(0, 0, 0, 0.05)',
  borderRadius: '21.8343px',
  height: "100%",
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center'
}
