import axios from 'axios';
import config from '../configs/config'

export const downloadFile = ({
	method = 'POST',
	url,
	params,
	filename = 'filename',
})=> {
  let token = localStorage.getItem('token') || false;
	
	axios({
		method,
		url: config.SERVER + url,
		data: params,
		headers: {
			'Authorization': `bearer ${token ? `${token}` : ''}`
		},
		responseType: 'blob'
	}).then(response => {
		const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute('download',`${filename}.xlsx`);
		link.click();
	})
	.catch(err => {
		console.error('err', err)
	})
}


export const downloadFileZip = ({
	method = 'POST',
	url,
	params,
	filename = 'filename',
})=> {
  let token = localStorage.getItem('token') || false;
	
	axios({
		method,
		url: config.SERVER + url,
		data: params,
		headers: {
			'Authorization': `bearer ${token ? `${token}` : ''}`
		},
		responseType: 'blob'
	}).then(response => {
		const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute('download',`${filename}.zip`);
		link.click();
	})
	.catch(err => {
		console.error('err', err)
	})
}