import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material';
import {
  useLocation,
  useParams
} from "react-router-dom";

import { Detail } from '../components/business-detail/detail'
import { getListBusinessFromGlobalCare } from '../../../../apis/carAccidentApis';


const ClaimDetails = (props) => {
  const { code } = useParams();
  const location = useLocation();
  const [details, setDetails] = useState({})
  const [historyLists, setHistoryLists] = useState([]);
  const [productCode, setProductCode] = useState(null);
  const [render, setRender] = useState(false);
  useEffect(() => {
    const params = `code=${code}`
    getListBusinessFromGlobalCare(params).then(res => {
      const data = res?.data?.result?.data || []
      if (data?.length > 0) setDetails(data[0])
    })
      .catch(err => {
        console.error(err)
      })
  }, [])

  return (
    <>
      {
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
            
          }}
        >
          <Detail details={details} historyLists={historyLists} code={code} />
        </Box>
      }

    </>
  )
};

export default ClaimDetails
