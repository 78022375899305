import React, { useRef } from 'react';
import {
  Box,
  Fade,
  Modal,
  Button,
  Typography,
  Backdrop,
  Divider
} from '@mui/material';

import imagePhotographyGuide from '../../../assets/images/photographyGuide.png'

const styleModal = {
  position: "absolute",
  bottom: "0px",
  left: "50%",
  width: "414px",
  transform: 'translate(-50%, 0%)',
  border: "none",
  background: '#fff',
  borderRadius: '16px 16px 0px 0px',
  p: 1
};

export default function ModalPhotographyGuide({ show, setShow }) {
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={show}>
        <Box style={styleModal}>
          <Box style={{ padding: 16 }}>
            <Box className="flex" style={{ justifyContent: "space-between" }}>
              <Box className="font-weight-600 font-size-16" textAlign="center" width="100%">
                Hướng dẫn chụp tình trạng xe
              </Box>
              <Box style={{ textAlign: "right" }} onClick={handleClose}>
                <img src="/core_tnds+vcx_oto/close-button.svg" />
              </Box>
            </Box>
          </Box>
          <Divider />
          <img src={imagePhotographyGuide} width="100%" />
        </Box>
      </Fade>
    </Modal>
  )
}