import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Container,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';

import { getListProviderSelected } from '../../../../../apis/companyManageApis';

const states = [
  { value: '1', label: 'Global Care', },
  { value: '2', label: 'Công ty bảo hiểm', },
  { value: '3', label: 'Merchant', },
]

const status = [
  { value: '1', label: 'Hoạt động', },
  { value: '2', label: 'Không hoạt động', },
]

export const AccountManagerSearch = ({
  handleSearch = () => { }
}) => {
  const [listProduct, setListProduct] = useState([])
  const [listProvider, setListProvider] = useState([])
  const user = localStorage.getItem('user') || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0

  const [values, setValues] = useState({
    fullname: '',
    email: '',
    username: '',
  });

  useEffect(() => {
    fetchListProduct()
    fetchListProvider()
  }, [])

  const fetchListProduct = () => {
  }

  const fetchListProvider = () => {
    getListProviderSelected().then(res => {
      const data = res?.data?.result?.data
      setListProvider(data)
    })
      .catch(err => {
        console.error('err', err)
      })
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const submit = (e) => {
    e.preventDefault();
  }

  return (
    <Container>
      <form
        // autoComplete="off"
        // noValidate
        // {...props}
        onSubmit={submit}
      >
        <Box>
          <Typography
            color="textPrimary"
            variant="h6"
            my={4}
          >
            Tra cứu tài khoản
          </Typography>
        </Box>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={3}
          >
            <TextField
              fullWidth
              placeholder="Nhập họ tên"
              label="Họ tên"
              name="fullname"
              onChange={handleChange}
              value={values.fullname}
              variant="outlined"
              focused
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              fullWidth
              placeholder="Nhập email"
              label="Email"
              name="email"
              onChange={(e) => handleChange(e)}
              value={values.email}
              variant="outlined"
              focused
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              fullWidth
              placeholder="Tên đăng nhập"
              label="User đăng nhập"
              name="username"
              onChange={handleChange}
              value={values.username}
              variant="outlined"
              focused
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormControl fullWidth focused>
              <InputLabel>Tình trạng</InputLabel>
              <Select
                fullWidth
                label="Tình trạng"
                name="status"
                onChange={handleChange}
                displayEmpty
                value={+values?.status || ''}
                variant="outlined"
                focused
              >
                <MenuItem value=''>Chọn tất cả</MenuItem>

                {status.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormControl fullWidth focused>
              <InputLabel style={{background: 'white'}}>Quyền truy cập hệ thống kênh quản lý</InputLabel>
              <Select
                fullWidth
                name="roleId"
                onChange={handleChange}
                select
                value={values.roleId || ''}
                displayEmpty
                variant="outlined"
                focused
              >
                <MenuItem value={''}>Chọn tất cả</MenuItem>
                {states.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Button
              type='submit'
              fullWidth
              color="gc"
              variant="contained"
              sx={{ height: "100%" }}
              onClick={handleSearch(values)}
            >
              Tra cứu
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
