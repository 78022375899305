import { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { BusinessReportTable } from '../components/business-report-table/table-list';
import { BusinessReportSearch } from '../components/business-report-table/search';
import { getListBusinessFromGlobalCare, getListBusinessFromProvider } from '../../../../apis/carAccidentApis';

const BusinessReport = (props) => {
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataFilter, setDataFilter] = useState(null)

  let productId = location?.state?.productId;
  let roleMenu = location?.state?.roleMenu;
  const user = localStorage.getItem('user') || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const systemType = (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0

  useEffect(() => {
    window.scrollTo({ top: 0 })
    const params = `limit=100&offset=0`
    fetchDataFromGlobalcare(params)
  }, [productId])

  useEffect(() => {
    if (!location?.pathname)
      return;

  }, [location])

  const fetchDataFromGlobalcare = (params) => {
    setIsLoading(true)
    getListBusinessFromGlobalCare(params).then(res => {
      const data = res?.data?.result?.data || []
      setListData(data)
      setIsLoading(false)
    })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
      })
  }

  const handleSearch = (dataSearch) => {
    dataSearch += `limit=100&offset=0`
    setDataFilter(dataSearch)
    fetchDataFromGlobalcare(dataSearch)
  }

  if (props.match === null) return <></>;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >

        <Container maxWidth={false}>
          <Stack spacing={5}>
            <BusinessReportSearch dataFilter={dataFilter} handleSearch={handleSearch} />

            <BusinessReportTable
              data={listData}
              isLoading={isLoading}
              productId={productId}
              roleMenu={roleMenu}
              dataFilter={dataFilter}
            />
          </Stack>
        </Container>
      </Box>
    </>
  )
};

export default BusinessReport
