import {
  Avatar,
  Box,
  Typography
} from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import api from '../../../../../configs/api';
import { updateProfile } from '../../../../../apis/authApis'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

export const AccountProfile = ({ profiles, loadProfiles }) => {
  const onClickUploadImage = async (e) => {
    const _files = e.target.files[0];

    const data = new FormData();
    data.append('avatar', _files);

    const response = await updateProfile(data)
    if (response?.status === 200) {
      loadProfiles();
    }
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: "left",
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box>
          <Avatar
            src={profiles.avatar || '/static/images/avatars/avatar_6.png'}
            sx={{
              height: 115,
              mb: 2,
              width: 115
            }}
          />
        </Box>
        <Box maxWidth="100%" ml={3}>
          <Typography
            display="flex"
            fullWidth
            mb={1}
            fontSize={22}
          >
            {profiles?.fullname}
          </Typography>
          <Typography
            component="label"
            fullWidth
            color="primary"
            sx={{ display: 'flex' }}
          >
            <input type="file" hidden onClick={(e) => e.target.value = ''} onChange={onClickUploadImage} accept="image/*" capture="camera" />
            <CameraAltOutlinedIcon /> &nbsp;
            Thay đổi ảnh đại diện
          </Typography>
        </Box>

      </Box>

    </>
  )
};
