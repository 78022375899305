import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from 'antd';

import {
  getListMerchant
} from '../../../../../apis/adminManagerAccountApis';
import './account-manager.css'

export const AccountSubMenu = ({
  handleSelectRole = () => {},
  handleSelectMerchant = () => {},
  manager = 1,
  title = '',
  roleData = [],
  userId = null,
  merchantId = null,
  userType = '',
  roleEdit = false,
}) => {

  const options = [
    { label: 'Tra cứu', value: 'search' },
    { label: 'Theo dõi hồ sơ', value: 'view' },
    { label: 'Xuất báo cáo', value: 'export' },
    { label: 'Tải giấy chứng nhận', value: 'download_certificate' },
  ];
  const optionsI = [
    { label: 'Tra cứu', value: 'search' },
    { label: 'Xử lý hồ sơ', value: 'view_and_edit' },
    { label: 'Phân công nhân sự xử lý hồ sơ', value: 'assign'},
    { label: 'Xuất báo cáo', value: 'export' },
  ];
  const [checkedList, setCheckedList] = useState({});
  const [checkedListI, setCheckedListI] = useState({});
  const [checkedListII, setCheckedListII] = useState({});
  const [checkedDashboard, setCheckedDashboard] = useState({});
  const [checkAll, setCheckAll] = useState({});
  const [checkAllI, setCheckAllI] = useState({});
  const [checkAllII, setCheckAllII] = useState({});
  const [checkAllRole, setCheckAllRole] = useState({});
  const [listSubMenu, setListSubMenu] = useState([]);
  const [listMerchant, setListMerchant] = useState([]);
  const [checkedMerchant, setCheckedMerchant] = useState([]);


  useEffect(() => {
    if(userId && listSubMenu.length > 0) {
      roleData?.map((item) => {
        let index = listSubMenu.findIndex( x => x?.product_id === item?.product_id);
        if(userType === 'provider' || userType === 'globalcare') {
          if(item?.full_access === 1) {
            handleFullRole(userType, true, index, item?.product_id, item?.feature_code)
          } else {
            let roleDashboard = item['dashboard'].full_access === 1 ? true : false
            setDataDetailsChecked(item, 'business_report', index, item?.product_id, item?.feature_code)
            if(userType === 'provider') setDataDetailsChecked(item, 'claim_approval', index, item?.product_id, item?.feature_code)
            setDataDetailsChecked(item, 'claim_report', index, item?.product_id, item?.feature_code)
            handleSelectRoleDashboard(roleDashboard,'dashboard', index, item?.product_id, item?.feature_code)
          }
        } else if(userType === 'merchant') {
          const newData = []
          newData[merchantId] = merchantId
          setCheckedMerchant(newData)
          handleSelectMerchant(merchantId)
          if(item?.full_access === 1) {
            handleFullRole(userType, true, index, item?.product_id, item?.feature_code)
          } else {
            let roleDashboard = item['dashboard'].full_access === 1 ? true : false
            setDataDetailsChecked(item, 'business_report', index, item?.product_id, item?.feature_code)
            handleSelectRoleDashboard(roleDashboard,'dashboard', index, item?.product_id, item?.feature_code)
          }
        }
      })
    }
  },[userId, userType, merchantId, listSubMenu])

  const setDataDetailsChecked = (item, type, index, productId, featureCode) => {
    if(item[type].full_access === 1)
      onCheckAllChange(true, type, index, productId, featureCode)
    else {
      let roleList = []
      let list = Object.keys(item[type])
      list?.map(v => {
        if(item[type][v] !== 0) {
          roleList.push(v)
        }
      })
      onChange(roleList, type, index, productId, featureCode)
    }
  }

  useEffect(() => {
    clearRoleData()
    fetchData()
    if(manager === 2) fetchMerchant()
  },[manager])

  const fetchData = () => {
  }

  const fetchMerchant = () => {
    getListMerchant().then(async res => {
      const data = res?.data?.result?.data
      setListMerchant(data)
    })
    .catch(err => {
      console.error('err', err)
    })
  }

  const clearRoleData = () => {
    setCheckedList({})
    setCheckedListI({})
    setCheckedListII({})
    setCheckAll({})
    setCheckAllI({})
    setCheckAllII({})
    setCheckAllRole({})
    setCheckedDashboard({})
    setListSubMenu([])
    setListMerchant([])
    setCheckedMerchant([])
  }

  const onChange = (list, type, index, id, featureCode) => {
    let listCheckAll = type === 'business_report' ? checkAll
      : type === 'claim_report' ? checkAllI : checkAllII
    let listChecked = type === 'business_report' ? checkedList
    : type === 'claim_report' ? checkedListI : checkedListII
    let optionsList = type === 'claim_approval' ? optionsI : options
    let newAll = listCheckAll || {}
    let newList = listChecked || {}
      newList[index] = list
    if(list.length === optionsList.length) {
      newAll[`${index}`] = true
    } else {
      newAll[`${index}`] = false
    }
    checkDataAllRole(list, index, id, type, featureCode)
    setDataSelected(newList, newAll, type)
  };

  const onSelectMerchant = value => () => {
    const newData = []
    newData[value] = value
    setCheckedMerchant(newData)
    handleSelectMerchant(value)
  };

  const handleSelectRoleDashboard = (checked, type, index, id, featureCode) => {
    let newList = checkedDashboard || {}
      newList[index] = checked
    setCheckedDashboard(newList)
    checkDataAllRole(checked,index, id, type, featureCode)
  }

  const onCheckAllChange = (checked, type, index, id, featureCode) => {
    let list = []
    let listCheckAll = type === 'business_report' ? checkAll
      : type === 'claim_report' ? checkAllI : checkAllII
    let listChecked = type === 'business_report' ? checkedList
    : type === 'claim_report' ? checkedListI : checkedListII
    let optionsList = type === 'claim_approval' ? optionsI : options
      optionsList?.map(item => list.push(item?.value))
    let newList = listChecked || {}
      newList[`${index}`] = checked ? list : []
    let newAll = listCheckAll || {}
      newAll[`${index}`] = checked
    
    checkDataAllRole(newList[index], index, id, type, featureCode)
    setDataSelected(newList, newAll, type)
  };

  const checkDataAllRole = (list, index, id, type, featureCode) => {
    const isCheck = manager === 1 ?
      checkAll[index] && checkAllI[index] && checkAllII[index] && checkedDashboard[index] :
      manager === 2 ? checkAll[index] && checkedDashboard[index] :
      checkAll[index] && checkedDashboard[index] && checkAllI[index]
    let newAll = checkAllRole || {}
      newAll[`${index}`] = isCheck
    handleSelectRole(list, id, type, isCheck, featureCode)
    setCheckAllRole({...newAll});
  }

  const setDataSelected = (list, listAll, type) => {
    if(type === 'business_report') {
      setCheckAll({...listAll});
      setCheckedList({...list});
    } else if(type === 'claim_report') {
      setCheckAllI({...listAll});
      setCheckedListI({...list});
    } else {
      setCheckAllII({...listAll});
      setCheckedListII({...list});
    }
  }

  const handleFullRole = (type, checked, index, id, feature_code) => {
    setAllRoleMenu(checked, index, id, feature_code)
  }

  const setAllRoleMenu = (checked, index, id, feature_code) => {
    let newAllRole = checkAllRole || {}
    let newAll = checkAll || {}
    let newAllI = checkAllI || {}
    let newAllII = checkAllII || {}
    let newList = checkedList || {}
    let newListI = checkedListI || {}
    let newListII = checkedListII || {}
    let newDashboard = checkedDashboard || {}
    let list = []
    let listI = []

    options?.map(item => list.push(item?.value))
    optionsI?.map(item => listI.push(item?.value))

    newAll[`${index}`] = checked
    newAllI[`${index}`] = checked
    newAllII[`${index}`] = checked
    newList[`${index}`] = checked ? list : []
    newListI[`${index}`] = checked ? list : []
    newListII[`${index}`] = checked ? listI : []
    newAllRole[`${index}`] = checked
    newDashboard[`${index}`] = checked
    
    setCheckAllRole({...newAllRole});
    setCheckAll({...newAll});
    setCheckAll({...newAllI});
    setCheckAll({...newAllII});
    setCheckedList({...newList});
    setCheckedListI({...newListI});
    setCheckedListII({...newListII});
    setCheckedDashboard({...newDashboard});
    handleSelectRole(list, id, 'onSelect', checked, feature_code)
  }

  const dataI = [
    {
      type: 'Quyền quản lý',
      id: 1
    }
  ]

  const renderItemMerchant = (item, index) => {
    return (
      <div key={index}>
        <Title>
          {item?.type || ''}
        </Title>
        <ItemContent
          container
          spacing={3}
          id='manager-global-care'
        >
          <Grid item xs={4}>
            <Checkbox
              onChange={e => handleFullRole('merchant', e.target.checked, index, item?.product_id, item?.feature_code)}
              checked={checkAllRole[index]}
              disabled={userId && !roleEdit}
            >
              Toàn quyền
            </Checkbox>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              onChange={(e) => handleSelectRoleDashboard(e.target.checked, 'dashboard', index, item?.product_id, item?.feature_code)}
              checked={checkedDashboard[index] || checkAllRole[index]}
              disabled={userId && !roleEdit}
            >
              Dashboard
            </Checkbox>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              onChange={(e) => onCheckAllChange(e.target.checked, 'business_report', index, item?.product_id, item?.feature_code)}
              checked={checkAllRole[index] || checkAll[index]}
              disabled={userId && !roleEdit}
            >
              Báo cáo kinh doanh
            </Checkbox>
            <Checkbox.Group
              style={{
                display: 'grid',
                padding: '0px 0 15px 44px',
              }}
              options={options}
              value={checkedList[index]}
              disabled={userId && !roleEdit}
              onChange={(e) => onChange(e, 'business_report', index, item?.product_id, item?.feature_code)}
            />
          </Grid>
        </ItemContent>
      </div>
    )
  }
  
  const renderItem = (item, index) => {
    if(manager === 1) {
      return(
        <div key={index}>
          <Title>
            {item?.product_title || ''}
          </Title>
          <Grid
            style={{
              borderBottom: '1px solid #E1E1E1',
              marginLeft: 0,
            }}
            container
            spacing={3}
            id='manager-global-care'
          >
            <Grid
              container
              style={{
                padding: '50px 0 10px 30px',
                borderBottom: '1px solid #E1E1E1',
              }}
            >
              <Grid item xs={4}>
                <Checkbox
                  onChange={e => handleFullRole('provider', e.target.checked, index, item?.product_id, item?.feature_code)}
                  checked={checkAllRole[index]}
                  disabled={userId && !roleEdit}
                >
                  Toàn quyền
                </Checkbox>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  onChange={(e) => handleSelectRoleDashboard(e.target.checked,'dashboard', index, item?.product_id, item?.feature_code)}
                  checked={checkedDashboard[index] || checkAllRole[index]}
                  disabled={userId && !roleEdit}
                >
                  Dashboard
                </Checkbox>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  onChange={(e) => onCheckAllChange(e.target.checked, 'business_report', index, item?.product_id, item?.feature_code)}
                  checked={checkAll[index] || checkAllRole[index] || false}
                  disabled={userId && !roleEdit}
                >
                  Báo cáo kinh doanh
                </Checkbox>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    padding: '0px 0 15px 44px',
                  }}
                  options={options}
                  value={checkedList[index]}
                  disabled={userId && !roleEdit}
                  onChange={e => onChange(e, 'business_report', index, item?.product_id, item?.feature_code)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                padding: '50px 0 10px 30px',
              }}
            >
              <Grid item xs={4}>
                <Checkbox
                  onChange={(e) => onCheckAllChange(e.target.checked, 'claim_report', index, item?.product_id, item?.feature_code)}
                  checked={checkAllI[index] || false}
                  disabled={userId && !roleEdit}
                >
                  Báo cáo bồi thường
                </Checkbox>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    padding: '0px 0 15px 44px',
                  }}
                  options={options}
                  value={checkedListI !== null ? checkedListI[index] : null}
                  disabled={userId && !roleEdit}
                  onChange={e => onChange(e, 'claim_report', index, item?.product_id, item?.feature_code)}
                />
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  onChange={(e) => onCheckAllChange(e.target.checked, 'claim_approval', index, item?.product_id, item?.feature_code)}
                  checked={checkAllII[index] || false}
                  disabled={userId && !roleEdit}
                >
                  Phê duyệt báo giá
                </Checkbox>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    padding: '0px 0 15px 44px',
                  }}
                  options={optionsI}
                  value={checkedListII !== null ? checkedListII[index] : null}
                  disabled={userId && !roleEdit}
                  onChange={e => onChange(e, 'claim_approval', index, item?.product_id, item?.feature_code)}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    }
    return(
      <div key={index}>
        <Title>
          {item?.product_title || ''}
        </Title>
        <ItemContent
          container
          spacing={3}
          id='manager-global-care'
        >
          <Grid item xs={4}>
            <div style={{flex: 1}}>
              <Checkbox
                onChange={e => handleFullRole('globalCare', e.target.checked, index, item?.product_id, item?.feature_code)}
                checked={checkAllRole[index]}
                disabled={userId && !roleEdit}
              >
                Toàn quyền
              </Checkbox>
            </div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 70,
              }}
            >
              <Checkbox
                onChange={(e) => handleSelectRoleDashboard(e.target.checked,'dashboard', index, item?.product_id, item?.feature_code)}
                checked={checkedDashboard[index] || checkAllRole[index]}
                disabled={userId && !roleEdit}
              >
                Dashboard
              </Checkbox>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              onChange={(e) => onCheckAllChange(e.target.checked, 'claim_report', index, item?.product_id, item?.feature_code)}
              checked={checkAllI[index] || false}
              disabled={userId && !roleEdit}
            >
              Báo cáo bồi thường
            </Checkbox>
            <Checkbox.Group
              style={{
                display: 'grid',
                padding: '0px 0 15px 44px',
              }}
              value={checkedListI !== null ? checkedListI[index] : null}
              options={options}
              onChange={e => onChange(e, 'claim_report', index, item?.product_id, item?.feature_code)}
              disabled={userId && !roleEdit}
            />
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              onChange={(e) => onCheckAllChange(e.target.checked, 'business_report', index, item?.product_id, item?.feature_code)}
              checked={checkAll[index] || checkAllRole[index] || false}
              disabled={userId && !roleEdit}
            >
              Báo cáo kinh doanh
            </Checkbox>
            <Checkbox.Group
              style={{
                display: 'grid',
                padding: '0px 0 15px 44px',
              }}
              options={options}
              value={checkedList[index]}
              disabled={userId && !roleEdit}
              onChange={e => onChange(e, 'business_report', index, item?.product_id, item?.feature_code)}
            />
          </Grid>
        </ItemContent>
      </div>
    )
  }

  return (
    <Item>
      <ItemInfo
        alignItems="center"
        justify="center"
      >
        <ItemTitle>
          {title}
        </ItemTitle>
      </ItemInfo>
      <div style={{overflowY: 'auto', maxHeight: 600}}>
        {manager === 2 ? (
          <>
            <Title>
              Merchant
            </Title>
            <Grid
              spacing={3}
              container
              id='manager-global-care'
              style={{
                padding: '20px 0 10px 55px',
              }}
            >
              {listMerchant?.map((item, index) => {
                return(
                  <Grid item xs={3}>
                    <Checkbox
                      onChange={onSelectMerchant(item?.id)}
                      checked={checkedMerchant[item?.id]}
                      disabled={userId || !roleEdit}
                    >
                      {item.title}
                    </Checkbox>
                  </Grid>
                )
              })}
            </Grid>
            {dataI?.map((item, index) => {return renderItemMerchant(item, index)})}
          </>) :
          listSubMenu?.map((item, index) => renderItem(item, index))
        }
      </div>
    </Item>
  )
};


const Item = styled(Grid)(() => ({
  background: '#FFF',
  borderRadius: 12,
  marginTop: 30,
}));

const ItemInfo = styled(Grid)(() => ({
  display: 'flex',
  background: '#E16B2F',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  height: 56,
}));

const ItemTitle = styled(Typography)(() => ({
  marginLeft: 36,
  fontSize: 23,
  fontWeight: '600',
  color: '#FFF'
}));

const ItemContent = styled(Grid)(() => ({
  padding: '50px 0 10px 30px',
  borderBottom: '1px solid #E1E1E1',
  marginLeft: 0,
}));

const Title = styled(Typography)(() => ({
  padding: '5px 36px 5px 36px',
  fontSize: 20,
  fontWeight: '600',
  background: '#EBEAEA'
}));
